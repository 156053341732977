<template>
  <page variant='g' class='venue'>

    <h1 class='heading'>{{ title }}</h1>

    <div class='sections'>

      <image-card class='image-card'
                  :image='location.image'></image-card>

      <card-container class='card-container' variant='a'>

        <location class='location'
                  :line1='location.line1'
                  :line2='location.line2'
                  :line3='location.line3'></location>

        <div class='text-wrapper' v-html='location.copy'></div>

      </card-container>



      <image-card class='image-card'
                  :image='social.image'></image-card>

      <card-container class='card-container' variant='b'>

        <h2 class='subheading'>{{ social.title }}</h2>

        <div class='text-wrapper' v-html='social.copy'></div>

      </card-container>



      <image-card class='image-card'
                  :image='hotel.image'></image-card>

      <card-container class='card-container' variant='a'>

        <h2 class='subheading'>{{ hotel.title }}</h2>

        <div class='text-wrapper' v-html='hotel.copy'></div>

        <cta-button class='cta'
          href='https://book.passkey.com/go/JAMStackConference2018'>Book Hotel</cta-button>

      </card-container>

    </div>

    <buy-now-section></buy-now-section>

    <footer-section></footer-section>

  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import ImageCard from '@/components/ImageCard.vue'
import CardContainer from '@/components/CardContainer.vue'
import Location from '@/components/Location.vue'
import BuyNowSection from '@/components/BuyNowSection.vue'
import FooterSection from '@/components/FooterSection.vue'
import CTAButton from '@/components/CTAButton.vue'

import venueContent from '@/content/venue.json'

export default {
  name: 'Venue',
  components: {
    Page,
    CardContainer,
    ImageCard,
    Location,
    BuyNowSection,
    FooterSection,
    'cta-button': CTAButton,
  },
  data: function() {
    return venueContent
  },
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.venue {

  .heading {
    font-size: $font-alternate-heading-size;
    text-align: center;

    margin-top: 70px;
    margin-bottom: 80px;

    @include break(small-and-medium) {
      margin-top: 110px;
    }
  }


  .sections {
    margin-bottom: 150px;
  }


  .image-card {
    margin-bottom: 40px;

    // These need to match <card-container> horizontal margins
    @include break(small) {
      margin-left: 20px;
      margin-right: 20px;
    }

    @include break(medium) {
      margin-left: 50px;
      margin-right: 50px;
    }

    @include break(large) {
      margin-left: auto;
      margin-right: auto;
      max-width: 730px;
    }
  }


  .card-container {
    margin-bottom: 200px;
  }


  .location {
    margin-bottom: 50px;

    width: 260px;
    margin-left: auto;
    margin-right: auto;

    @include break(small) {
      font-size: $font-small-size;
    }
  }


  .subheading {
    font-size: $font-alternate-heading-size;
    font-weight: $font-bold-weight;
    text-align: center;

    margin-bottom: 40px;
  }


  .text-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }


  .cta-button {
    margin-top: 60px;

    margin-left: auto;
    margin-right: auto;
    max-width: 270px;
  }


}
</style>
