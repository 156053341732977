<template>
  <div class='card-container' :class='{ "no-shadow": noShadow }'>
    <div class='wrapper' :class='{ wide: wide }'>
      <slot></slot>
    </div>

    <div v-if='variant === "a"'>
      <div class='shape shape--a'></div>
    </div>
    <div v-if='variant === "b"'>
      <div class='shape shape--b'></div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'CardContainer',
  props: [
    'variant',
    'wide',
    'noShadow',
  ],
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.card-container {

  background-color: #FFFFFF;
  box-shadow: $small-box-shadow;

  position: relative;

  @include break(small) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @include break(medium) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @include break(large) {
    margin-left: auto;
    margin-right: auto;
    max-width: 730px;
  }


  &.no-shadow {
    // Kill the shadow and margins
    box-shadow: none;

    .wrapper {
      padding-left: 14px;
      padding-right: 14px;
    }
  }


  .wrapper {
    padding-top: 80px;
    padding-bottom: 70px;

    @include break(small) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include break(medium) {
      padding-left: 50px;
      padding-right: 50px;
    }

    @include break(large) {
      padding-left: 150px;
      padding-right: 150px;
    }

    &.wide {
      // Override horizontal padding
      @include break(small) {
        padding-left: 14px;
        padding-right: 14px;
      }
      @include break(medium) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include break(large) {
        padding-left: 50px;
        padding-right: 50px;
      }
    }

  }


  .shape {
    margin-left: auto;
    margin-right: auto;
    height: 34px;

    position: absolute;
    bottom: -35px;
    z-index: 1;
    left: 0;
    right: 0;


    @include break(small-and-medium) {
      width: 300px;
    }


    @include break(large) {
      width: 500px;
    }


    &--a {
      @include break(small-and-medium) {
        background-image: url("../assets/card-container/shape-a-small.svg");
      }

      @include break(large) {
        background-image: url("../assets/card-container/shape-a-large.svg");
      }

    }

    &--b {
      @include break(small-and-medium) {
        background-image: url("../assets/card-container/shape-b-small.svg");
      }

      @include break(large) {
        background-image: url("../assets/card-container/shape-b-large.svg");
      }
    }

  }

}
</style>
