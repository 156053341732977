<template>
  <div class='image-card' :class='{ flipped: flipped }'>
    <img :src='image'/>
  </div>
</template>


<script>
export default {
  name: 'ImageCard',
  props: [
    'image',
    'flipped',
  ],
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.image-card {
  background-color: #FFFFFF;
  box-shadow: $small-box-shadow;

  padding: 12px;

  margin-left: 20px;
  margin-right: 20px;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}
</style>
