<template>
  <card-container class='schedule-which-ticket-section'
                  variant='a'
                  wide='1'>

    <h2 class='heading'>Which ticket should I get?</h2>

    <!-- I'm sorry I made a table an image.
         It happened under intense time pressure. -->

    <picture class='table'>
       <source media='(min-width: 851px)'
          srcset='@/assets/schedule-sections/which-ticket/which-ticket-large.png'/>
       <source media='(max-width: 850px)'
          srcset='@/assets/schedule-sections/which-ticket/which-ticket-small.png'/>
       <img src='@/assets/schedule-sections/which-ticket/which-ticket-large.png'/>
     </picture>

  </card-container>
</template>


<script>
import CardContainer from '@/components/CardContainer.vue'

export default {
  name: 'ScheduleWhichTicketSection',
  components: {
    CardContainer,
  },
}
</script>


<style scoped lang='scss'>
@import "../../css/theme";

.schedule-which-ticket-section {

  margin-bottom: 120px;


  .heading {
    font-size: $font-alternate-heading-size;
    font-weight: $font-bold-weight;
    text-align: center;

    margin-bottom: 50px;

    @include break(small) {
      font-size: $font-medium-size;
    }
  }


  .table {

    img {
      display: block;
      width: 100%;
      height: auto;

      @include break(small-and-medium) {
        margin-left: auto;
        margin-right: auto;
        max-width: 296px;
      }

      @include break(large) {
        max-width: 600px;
      }
    }
  }

}
</style>

