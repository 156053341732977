<template>
  <div class='page-nav'>
    <img class='logo' src='@/assets/jamstack-conf-logo.svg'/>

    <ul class='link-list'>
      <li class='link-item'
          v-for='link in links'
          :key='link.url'>
        <a v-if='isExternalLink(link.url)'
           class='link'
           :href='link.url'
           target='_blank'>{{ link.label }}</a>
        <router-link v-else
                     class='link'
                     :to='link.url'>{{ link.label }}</router-link>
      </li>
    </ul>

  </div>
</template>


<script>
import content from '@/content/nav.json'

export default {
  name: 'PageNav',
  props: ['variant'],
  data: function() {
    return content;
  },
  methods: {
    isExternalLink: function(url) {
      if (url.startsWith('/')) {
        return false;
      }
      return true;
    }
  },
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.page-nav {

  display: none;


  @include break(large) {
    display: block;
    padding-top: 50px;
  }


  .logo {
    @include break(large) {
      width: 325px;
      height: auto;

      display: block;
      margin-left: auto;
      margin-right: auto;
    }

  }

  
  .link-list {
    list-style-type: none;

    @include break(large) {
      margin-left: -30px;  // Not sure why this isn't centered perfectly

      display: flex;
      justify-content: center;
      align-items: center;
    }

  }


  .link-item + .link-item {
    margin-left: 25px;
  }


  .link,
  .link:visited {
    color: $text-color;
    text-decoration: none;

    font-size: $font-small-size;
    font-weight: $font-medium-weight;


  }


  .link:hover,
  .link.router-link-exact-active {
    position: relative;

    &::before {
      content: "";
      display: block;
      height: 10px;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;

      background-color: $aqua-highlight-color;
    }

  }



}

</style>
