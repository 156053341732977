<template>
  <div class='hero-container'>
    <div class='wrapper'>
      <slot></slot>
    </div>
  </div>
</template>


<script>

export default {
  name: 'HeroContainer',
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.hero-container {

  .wrapper {
    // These paddings must be at least as big as Page.vue shapes

    @include break(small) {
      padding-left: 50px;
      padding-right: 50px;

      padding-top: 120px;
      padding-bottom: 120px;
    }

    @include break(medium) {
      padding-left: 80px;
      padding-right: 80px;

      padding-top: 120px;
      padding-bottom: 120px;
    }

    @include break(large) {
      padding-left: 200px;   // At least as big as Page.vue shape
      padding-right: 200px;

      margin-top: 80px;
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;

      padding-bottom: 120px;
    }

  }
  
}

</style>
