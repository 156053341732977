<template>
  <page variant='d' class='schedule-page'>

    <h1 class='heading'>Schedule</h1>

    <div class='sections'>
      <schedule-intro-section></schedule-intro-section>

      <schedule-agency-day-section
        :title='agencyDay.title'
        :date='agencyDay.date'
        :note='agencyDay.note'
        :copy='agencyDay.copy'
        :schedule='agencyDay.schedule'
        :extra='agencyDay.extra'></schedule-agency-day-section>

      <schedule-reception-section
        :title='reception.title'
        :date='reception.date'
        :copy='reception.copy'
        :schedule='reception.schedule'
        :extra='reception.extra'></schedule-reception-section>

      <schedule-conference-section
        :title='conference.title'
        :date='conference.date'
        :copy='conference.copy'
        :schedule='conference.schedule'
        :extra='conference.extra'></schedule-conference-section>

      <schedule-which-ticket-section></schedule-which-ticket-section>

    </div>

    <buy-now-section></buy-now-section>

    <footer-section></footer-section>

  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import ScheduleIntroSection from '@/components/schedule-sections/ScheduleIntroSection.vue'
import ScheduleAgencyDaySection from '@/components/schedule-sections/ScheduleAgencyDaySection.vue'
import ScheduleConferenceSection from '@/components/schedule-sections/ScheduleConferenceSection.vue'
import ScheduleReceptionSection from '@/components/schedule-sections/ScheduleReceptionSection.vue'
import ScheduleWhichTicketSection from '@/components/schedule-sections/ScheduleWhichTicketSection.vue'
import BuyNowSection from '@/components/BuyNowSection.vue'
import FooterSection from '@/components/FooterSection.vue'

import scheduleContent from '@/content/schedule.json'

export default {
  name: 'Schedule',
  components: {
    Page,
    ScheduleIntroSection,
    ScheduleAgencyDaySection,
    ScheduleConferenceSection,
    ScheduleReceptionSection,
    ScheduleWhichTicketSection,
    BuyNowSection,
    FooterSection,
  },
  data: function() {
    return scheduleContent
  },
}
</script>



<style scoped lang='scss'>
@import "../css/theme";

.schedule-page {

  .heading {
    font-size: $font-alternate-heading-size;
    text-align: center;

    margin-top: 70px;
    margin-bottom: 80px;

    @include break(small-and-medium) {
      margin-top: 110px;
    }
  }

  .sections {
    margin-bottom: 150px;
  }

}
</style>
