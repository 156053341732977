<template>
  <div class='wrapper' v-responsive='{
    mobile: el => el.width <= 850,
    desktop: el => el.width > 850,
  }'>
    <transition name='scroll-slide'>
      <div class='nav'
           v-if='shouldShowNavBar'
           v-bind:class='{ shadow: needsDropShadow }'>

        <router-link to="/">
          <transition name='fade'>
            <img class='logo'
                 v-if='!hideLogo'
                 src='@/assets/jamstack-conf-logo.svg'/>
          </transition>
        </router-link>


        <transition name='mobile-slide'>
          <div class='mobile-menu' v-if='isOpen'>

            <div class='link-item' v-for='link in navContent.links' :key='link.url'>
              <a v-if='isExternalLink(link.url)'
                 :href='link.url'
                 target='_blank'>{{ link.label }}</a>
              <router-link v-else
                           class='link'
                           :to='link.url'>{{ link.label }}</router-link>
            </div>
            <a href='https://ti.to/netlify/jamstackconf' target='_blank'>Get Tickets</a>
          </div>
        </transition>


        <hamburger-icon class='hamburger'
                        v-bind:isOpen='isOpen'
                        v-on:click='onHamburgerClick'>
        </hamburger-icon>


        <div class='desktop-menu'>
          <span class='link-item' v-for='link in navContent.links' :key='link.url'>
            <a v-if='isExternalLink(link.url)'
               :href='link.url'
               target='_blank'>{{ link.label }}</a>
            <router-link v-else
                         class='link'
                         :to='link.url'>{{ link.label }}</router-link>
          </span>
        </div>

        <a class='desktop-menu-cta' href='https://ti.to/netlify/jamstackconf' target='_blank'>Get Tickets</a>

      </div>
    </transition>
  </div>
</template>


<script>
import HamburgerIcon from '@/components/HamburgerIcon.vue'

import navContent from '@/content/nav.json'

export default {
  name: 'NavBar',
  props: {
    initialOpen: Boolean,
    msg: String
  },
  data: function() {
    return {
      isOpen: this.initialOpen,
      lastScrollPosition: 0,
      needsDropShadow: false,
      shouldShowNavBar: false,
      hasScrolled: false,
      hideLogo: false,
      navContent: navContent,
    }
  },
  components: {
    HamburgerIcon
  },
  watch: {
    '$route' () {
      this.isOpen = false;
    }
  },
  methods: {
    onHamburgerClick: function() {
      this.isOpen = !this.isOpen;
      this.updateShouldShowNavBar();
    },

    onScroll: function() {
      // TODO: THROTTLE THIS!

      if (this.isOpen) {
        // No closing while the menu is open!
        return;
      }
      if (this.lastScrollPosition <= 0) {
        this.lastScrollPosition = window.scrollY;
        return;
      }

      if (this.lastScrollPosition < window.scrollY) {
        // Hide the menu
        this.hasScrolled = true;
      } else if (this.lastScrollPosition > window.scrollY) {
        // Show the menu
        this.hasScrolled = false;

        if (window.scrollY > 100) {
          this.needsDropShadow = true;
        } else {
          this.needsDropShadow = false;
        }
      }
      this.updateShouldShowNavBar();
      this.lastScrollPosition = window.scrollY;
    },

    updateShouldShowNavBar: function() {
      // This got messy
      if (window.matchMedia("(max-width: 850px)").matches) {
        if (window.scrollY === 0) {
          this.shouldShowNavBar = true;
        } else {
          this.shouldShowNavBar = !this.hasScrolled;
        }
        if (this.$route.name !== 'Home') {
          // Always show the logo on non-home-pages
          this.hideLogo = false;
        } else {
          if (this.isOpen) {
            // Can't hide the logo if the nav is open
            this.hideLogo = false;
          } else {
            // Hide the logo if we can still see the home page JAMStack_conf
            // logo
            if (window.scrollY < 300) {
              this.hideLogo = true;
            } else {
              this.hideLogo = false;
            }
          }
        }
      } else {
        if (window.scrollY < 200) {
          this.shouldShowNavBar = false
        } else {
          this.shouldShowNavBar = !this.hasScrolled;
        }
        // Always show the logo for desktop nav
        this.hideLogo = false;
      }

    },

    isExternalLink: function(url) {
      if (url.startsWith('/')) {
        return false;
      }
      return true;
    }
  },
  mounted: function() {
    setTimeout(() => {
      // This is a little hacky. Since the method below relies on
      // `window.scrollY`, // we need to wait to call it until after VueRouter
      // switches to the new // view and scrolls to the top of the page.
      this.updateShouldShowNavBar();
    }, 1);
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed: function() {
    window.removeEventListener('scroll', this.onScroll);

  }
}
</script>


<style scoped lang='scss'>

.nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;

  padding-left: 20px;
  padding-right: 20px;
  height: 60px;


  transition-property: transform, opacity, box-shadow;
  transition-duration: .3s;
  transition-timing-function: ease-out;


  &.scroll-slide-enter {
    transform: translate(0, -100%);
    opacity: 0;
  }

  &.scroll-slide-enter-to {
    transform: translate(0, 0);
    opacity: 1;
  }

  &.scroll-slide-leave {
    transform: translate(0, 0);
    opacity: 1;
  }

  &.scroll-slide-leave-to {
    transform: translate(0, -100%);
    opacity: 0;
  }

  &.shadow {
    box-shadow: 0 2px 4px rgba(0,0,0, .04);
  }
}


.mobile {

  .desktop-menu,
  .desktop-menu-cta {
    display: none;
  }


  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .logo,
  .hamburger {
    /* Make sure these are still clickable */
    position: relative;
    z-index: 1002;
  }


  .logo {
    &.fade-leave-active,
    &.fade-enter-active {
      transition: opacity .2s ease-out;
    }

    &.fade-enter {
      opacity: 0;
    }

    &.fade-enter-to {
      opacity: 1;
    }

    &.fade-leave {
      opacity: 1;
    }

    &.fade-leave-to {
      opacity: 0;
    }
  }


  .mobile-menu {
    display: none;
    position: relative;

    box-sizing: border-box;
    background-color: #FFF;
    border-right: 1px solid rgba(0,0,0,.05);
    border-bottom: 1px solid rgba(0,0,0,.05);

    box-shadow: 0 6px 34px rgba(83, 40, 255, .15);

    font-size: 22px;
    font-weight: 500;

    padding-top: 80px;   /* Match #nav height + 20px */
    padding-left: 20px;
    padding-right: 20px;

    position: absolute;
    top: 0;
    left: 0;
    right: 65px;
    z-index: 1001;

    height: 97vh;

    display: flex;
    flex-direction: column;
    align-items: flex-start;


    transition-property: transform, opacity;
    transition-duration: .3s;
    transition-timing-function: ease-out;


    &.mobile-slide-enter {
      opacity: 0;
      transform: translate(-100%, 0);
    }

    &.mobile-slide-enter-to {
      opacity: 1;
      transform: translate(0, 0);
    }

    &.mobile-slide-leave {
      opacity: 1;
      transform: translate(0, 0);
    }

    &.mobile-slide-leave-to {
      opacity: 0;
      transform: translate(-100%, 0);
    }


    &::before {
      content: "";
      background-image: url("../assets/nav-bar/nav-bar__shape-1.svg");
      width: 47px;
      height: 44px;
      display: block;

      position: absolute;
      bottom: 0;
      left: 0;
    }


    &::after {
      content: "";
      background-image: url("../assets/nav-bar/nav-bar__shape-2.svg");
      width: 161px;
      height: 287px;
      display: block;

      position: absolute;
      bottom: 0;
      right: 0;
    }


    a {
      display: block;

      color: #000;
      font-weight: 500;
      font-size: 20px;
      text-decoration: none;

      padding-top: 20px;
      padding-bottom: 20px;
    }


    a.router-link-exact-active {

      position: relative;

      &::before {
        content: "";
        display: block;
        height: 16px;

        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        z-index: -1;

        background-color: rgba(32, 245, 217, .44);
      }

    }

  }

}


.desktop {

  .hamburger {
    display: none;
  }


  .nav {
    /* Note that nav-bar *cannot* be fixed-width because the background images
     in the heros need to line up with the CTA button, and those images are
     always left/right aligned to the browser. */

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }


  .logo {
    margin-right: 40px;
  }


  .desktop-menu {

    a {
      color: #000;
      font-weight: 500;
      font-size: 14px;
      text-decoration: none;

      margin-right: 30px;
    }


    a:hover,
    a.router-link-exact-active {

      position: relative;

      &::before {
        content: "";
        display: block;
        height: 10px;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        background-color: rgba(32, 245, 217, .44);
      }

    }
  }

  .desktop-menu-cta {
    color: #000;
    font-weight: 500;
    font-size: 13px;
    text-decoration: none;
    font-weight: 700;

    background-color: #FFF;
    border: 4px solid blue;
    border-image: linear-gradient(to right, #20F5D9, #5328FF);
    border-image-slice: 4;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 18px;
    padding-right: 18px;

    cursor: pointer;

    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 100;

    &:hover {
      color: #B65ADC;
      border-image: linear-gradient(to right, #CD63FF, #FF52D9);
      border-image-slice: 4;
      outline: none;
    }

    &:active {
      color: #000;
      border: 4px solid #000;
      border-image: none;
      outline: none;
    }
  }

}
</style>
