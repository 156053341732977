<template>
  <page variant='i' class='jobs'>

    <h1 class='heading'>Jobs</h1>

    <div class='intro' v-html='introCopy'></div>

    <div class='jobs-list'>

      <job-card v-for='job in jobsList'
           class='job'
           :key='job.url'
           :title='job.title'
           :logo='job.logo'
           :description='job.description'
           :location='job.location'
           :url='job.url'>
        </job-card>

    </div>

    <footer-section></footer-section>

  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import JobCard from '@/components/JobCard.vue'
import FooterSection from '@/components/FooterSection.vue'

import jobsContent from '@/content/jobs.json'

export default {
  name: 'Venue',
  components: {
    Page,
    JobCard,
    FooterSection,
  },
  data: function() {
    return jobsContent
  },
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.jobs {

  .heading {
    font-size: $font-alternate-heading-size;
    text-align: center;

    margin-top: 70px;

    @include break(small-and-medium) {
      margin-top: 110px;
    }
  }


  .intro {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;

    margin-bottom: 90px;

    padding-left: 40px;
    padding-right: 40px;
  }


  .jobs-list {
    margin-bottom: 150px;
  }

}
</style>
