<template>
  <div class='buy-now-section'>

    <cta-button class='cta'
      href='https://ti.to/netlify/jamstackconf'>Get Tickets</cta-button>

    <p>Sending three or more from your team? Contact us about 
      <a href='mailto:info@jamstackconf.com?subject=Group%20Discounts'>Group Discounts</a> or 
      <a href='mailto:sponsors@jamstackconf.com?subject=Sponsor%20Inquiry'>Sponsorships</a>
     </p>

  </div>
</template>


<script>
import CTAButton from '@/components/CTAButton.vue'

export default {
  name: 'BuyNowSection',
  components: {
    'cta-button': CTAButton
  },
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.buy-now-section {

  font-size: $font-small-size;
  text-align: center;

  margin-bottom: 120px;
  padding-left: 20px;
  padding-right: 20px;

  margin-left: auto;
  margin-right: auto;
  max-width: 380px;


  .cta {
    margin-left: auto;
    margin-right: auto;
    max-width: 270px;

    margin-bottom: 40px;
  }

}

</style>
