<template>
  <page variant='b' class='code-of-conduct'>

    <h1 class='heading'>Code of Conduct</h1>

    <card-container class='wrapper' variant='a'>

      <div class='sections'>
        <div class='section'>

          <p>JAMstack_conf is not just a conference...it’s a community! We strive
          to create an inclusive, judgement- and harassment-free place for
          individuals of all backgrounds and levels of experience to learn,
          connect, and grow together.</p>

          <p>Every person has their personal story that has shaped their unique
          perspective. This can be a fascinating thing! Everyone you meet has the
          potential to amaze you with the things they’ve done and the things they
          know. However, it can also create misunderstandings, which can
          potentially lead to conflict.
          </p>

          <p>In order to mitigate conflict, and to create a more welcoming, safe
          atmosphere, all conference attendees, sponsors, speakers, vendors,
          volunteers, staff and organizers shall:</p>

          <h3>1. Be considerate</h3>

          <p>Approach your interactions with thoughtfulness and care. Recognize the
          myriad differences between yourself and others, and that you may not view
          the world through the same lens. Do not dismiss someone because they have
          a different level of experience, are of a different background, or have a
          difference of opinion. Be kind to others.</p>

          <h3>2. Be respectful</h3>

          <p>Disagreement is not an excuse for poor manners. We work together to
          resolve conflict, assume good intentions and do our best to act in an
          empathic fashion. We don't allow frustration to turn into a personal
          attack. A community where people feel uncomfortable or threatened is not
          a productive one.</p>

          <h3>3. Take responsibility for your words and actions</h3>

          <p>We all make mistakes. When we do, we take responsibility for them. If
          someone has been harmed or offended, we listen carefully and
          respectfully, and work to right the wrong. We should strive to apologize
          and forgive when possible.</p>

          <h3>4. Recognize the reasons for disagreement</h3>

          <p>If everyone agreed on everything, there would be no progress.
          Differences of opinion are the foundation of progress, both socially and
          technologically. It is important to recognize the benefit of such
          differing perspectives, and to remain respectful in both the expression
          of your ideas and the interpretation of another’s. Such conversations of
          disagreement should be conducted with consideration, in a respectful
          manner, focusing on a collaborative, rather than combative, goal.</p>

        </div>


        <div class='section'>

          <h2>Anti-Harassment Policy</h2>

          <p>JAMstack_conf is dedicated to providing a harassment-free conference
          experience for everyone, regardless of gender, gender identity and
          expression, age, sexual orientation, disability, physical appearance,
          body size, race, ethnicity, religion (or lack thereof), or technology
          choices. We do not tolerate harassment of conference participants in any
          form. Sexual language and imagery is not appropriate for any conference
          venue, including talks, workshops, parties, Twitter and other online
          media. Conference participants violating these rules may be sanctioned or
          expelled from the conference without a refund at the discretion of the
          conference organisers.</p>

          <p>Sponsors should not use sexualized images, activities, or other
          material. Booth staff (including volunteers) should not use sexualized
          clothing/uniforms/costumes, or otherwise create a sexualized
          environment.</p>

          <p>Harassment includes, but is not limited to:</p>

          <ul>
            <li>Verbal or written offensive comments related to gender, gender
              identity and expression, sexual orientation, disability, physical
              appearance, body size, race, age, or religion</li>

            <li>Sexual images in public spaces</li>

            <li>Deliberate intimidation, stalking, or following </li>

            <li>Harassing photography or recording</li>

            <li>Sustained disruption of talks or other events</li>

            <li>Inappropriate physical contact</li>

            <li>Unwelcome sexual attention</li>

            <li>Advocating for, or encouraging, any of the above behaviour</li>
          </ul>


          <p>We will not act on complaints regarding:</p>

          <ul>
            <li>‘Reverse’ -isms, including ‘reverse racism,’ ‘reverse sexism,’ and
              ‘cisphobia’ (because these things don’t exist)</li>

            <li>Reasonable communication of boundaries, such as “leave me alone,”
              “go away,” or “I’m not discussing this with you.”</li>

            <li>Refusal to explain or debate social justice concepts</li>

            <li>Communicating in a ‘tone’ you don’t find congenial</li>

            <li>Criticizing racist, sexist, cissexist, or otherwise oppressive behavior
            or assumptions</li>
          </ul>

          <h3>Bathroom Policy</h3>

          <p>Diversity and self-expression are important in our world -  both at JAMStack_conf and beyond.</p>

          <p>Therefore, we encourage all attendees at the conference to use the bathroom they feel most comfortable in, regardless of gender expression. Should you feel inclined to question someone's bathroom choice, please don't - we feel sure they know quite well where they belong.</p>

          <p>If you witness someone being made to feel unwelcome, or someone makes you feel unwelcome while using the facilities, please reach out to a conference staff member so we can assist you.</p>

        </div>


        <div class='section'>

          <h2>Reporting</h2>

          <p>If you experience or witness unacceptable behavior—or have any other
          concerns—please report it by contacting us at <a target='_blank'
            href='mailto:ben.dunphy@netlify.com'>ben.dunphy@netlify.com</a>, or
          by approaching a member of staff at the event to assist with submitting a
          written report. Conference members can be identified by a clearly-marked
          “STAFF” t-shirt.</p>

          <p>All reports will be handled with discretion. In your report please
          include:<p>

          <ul>
            <li>Your contact information.</li>
            <li>Names (real, nicknames, or pseudonyms) of any individuals involved.</li>
            <li>If there are additional witnesses, please include them as well.</li>
            <li>Your account of what occurred, and if you believe the incident
              is ongoing.</li>
            <li>If there is a publicly available record (e.g. text
              messages, emails, slack messages), please include a link.</li>
            <li>Any additional information that may be helpful.</li>
          </ul>

          <p>After filing a report, a representative will contact you personally,
          review the incident, follow up with any additional questions, and make a
          decision as to how to respond. If the person who is harassing you is part
          of the response team, they will recuse themselves from handling your
          incident. If the complaint originates from a member of the response team,
          it will be handled by a different member of the response team. We will
          respect confidentiality requests for the purpose of protecting victims of
          abuse.</p>

          <p><strong>Anonymous reports</strong> can be made <a target='_blank'
            href='https://docs.google.com/forms/d/e/1FAIpQLSc-ZnSPXHui0tjqL-2SRXBgvN89__bKlCKdgk3BncQuCu8wTw/viewform'>here</a>.</p>

          <p>We can't follow up an anonymous report with you directly, but we will
          fully investigate it and take whatever action is necessary to prevent a
          recurrence.</p>

        </div>


        <div class='section'>

          <h2>Enforcement</h2>

          <p>Adherence to and acceptance of this Code of Conduct is a condition of
          entry. Participants asked to stop any harassing behavior are expected to
          comply immediately. This includes all attendees, sponsors, speakers,
          organizers, volunteers, and venue or vendor staff.</p>

          <p>If a participant engages in harassing behaviour, event organizers
          retain the right to take any actions to keep the event a welcoming
          environment for all participants. This includes but is not limited to
          warning the offender or expulsion from the conference without the
          possibility for refund.</p>

          <p>The organizers may also, at their sole discretion, choose to offer an
          alternative option to immediate removal from the event:</p>

          <div class='dara'>'Desist, Apologize, Refrain, and Avoid', (DARA)</div>

          <p>The DARA alternative exists because we acknowledge that sometimes a
          breach of conduct can happen without malicious intent. In this event the
          organizers will explain to violators what happened and why it was a
          breach of conduct, and request that they:</p>

          <ul>
            <li><strong>Desist</strong> from the action which caused the breach;</li>
            <li><strong>Apologize</strong> to the person or people affected by the breach;</li>
            <li><strong>Refrain</strong> from breaching the code again;</li>
            <li><strong>Avoid</strong> contentious discussion about the breach itself.</li>
          </ul>

          <p>Once an apology has been made in good faith, violators are expected to
          conduct themselves in a professional manner without dwelling on the
          issue, without trying to justify bad behaviour, and without keeping the
          issue 'alive' once these actions have taken place. Those offered the DARA
          alternative who fail to adhere to all four points may be expelled from
          the event without refund.</p>

        </div>


        <div class='section'>

          <h2>Attribution &amp; Acknowledgements </h2>

          <p>We would like to thank the following conferences and communities for
          their inspiration and assistance in drafting our own code of conduct:</p>

          <ul>
            <li><a href='https://jsconf.com/codeofconduct.html' target='_blank'>JSConf</a></li>
            <li><a href='http://www.swiftsummit.com/codeofconduct'
                target='_blank'>Swift Summit</a></li>
            <li><a href='https://www.djangoproject.com/conduct/reporting/'
                target='_blank'>Django</a></li>
            <li><a href='https://www.python.org/psf/codeofconduct/'
                target='_blank'>Python</a></li>
            <li><a href='https://www.ubuntu.com/community/code-of-conduct'
                target='_blank'>Ubuntu </a></li>
            <li><a href='https://geekfeminism.org/about/report-abuse/'
                target='_blank'>Geek Feminism</a></li>
            <li><a
                href='https://www.ng-conf.org/wp-content/uploads/2017/03/Code-of-Conduct.pdf'
                target='_blank'>NG Conf</a></li>
          </ul>

        </div>
      </div>
    </card-container>


    <footer-section></footer-section>

  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import CardContainer from '@/components/CardContainer.vue'
import FooterSection from '@/components/FooterSection.vue'

export default {
  name: 'CodeOfConduct',
  components: {
    Page,
    CardContainer,
    FooterSection,
  }
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.code-of-conduct {

  .heading {
    font-size: $font-alternate-heading-size;
    text-align: center;

    margin-top: 70px;
    margin-bottom: 80px;

    padding-left: 20px;
    padding-right: 20px;

    @include break(small-and-medium) {
      margin-top: 110px;
    }
  }


  h2 {
    font-size: $font-subheading-size;
    margin-top: 40px;

    @include break(small) {
      font-size: $font-bigger-size;
    }
  }


  h3 {
    font-size: $font-bigger-size;

    @include break(small) {
      font-size: $font-text-size;
    }
  }


  .wrapper {
    margin-bottom: 150px;
  }

  .sections {
    font-size: $font-small-size;

    @include break(small-and-medium) {
      margin-top: -40px;
    }
  }

}
</style>
