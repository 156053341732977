<template>
  <div class='job-card'>

    <div class='content'>

      <div class='meta'>
        <img class='logo' :src='logo'/>
        <div class='location'>{{location}}</div>
      </div>

      <div class='body'>
        <div class='title'>{{title}}</div>
        <div class='description' v-html='description'></div>
      </div>

    </div>

    <a class='link' :href='url' target='_blank'>See Job Listing</a>

  </div>
</template>


<script>
export default {
  name: 'JobCard',
  props: [
    'title',
    'logo',
    'description',
    'location',
    'url',
  ],
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.job-card {

  background-color: #FFF;
  box-shadow: 0 6px 34px rgba(83, 40, 255, .15);


  @include break(small-and-medium) {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 60px;
  }


  @include break(medium) {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }


  @include break(large) {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }


  .content {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }


  .meta {
    @include break(medium-and-large) {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }


  .logo {
    width: auto;
    height: 40px;

    @include break(small) {
      margin-bottom: 10px;
    }
  }


  .location {
    font-size: $font-small-size;
  }


  .body {
    @include break(large) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }


  .title {
    font-weight: $font-bold-weight;
    font-size: $font-medium-size;

    @include break(large) {
      flex: 2;
      margin-right: 30px;
    }
  }

  .description {
    font-size: $font-small-size;

    @include break(large) {
      flex: 3;
    }
  }


  .link:link,
  .link:visited {
    display: block;
    color: #FFFFFF;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;

    font-weight: $font-bold-weight;


    @include break(small-and-medium) {
      text-align: center;
    }


    @include break(large) {
      text-align: right;
    }


    &::after {
      // Arrow after link
      content: "";

      display: inline-block;
      vertical-align: middle;

      margin-left: 5px;

      background-image: url('../assets/job-card/jobs-link-arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;

      width: 18px;
      height: 24px;
    }
  }


  .link:hover,
  .link:visited:hover {
    text-decoration: none;
    opacity: .8;
  }


  &:nth-of-type(4n+1) .link {
    background-color: $purple-color;
  }

  &:nth-of-type(4n+2) .link {
    background-color: $pink-color;
  }

  &:nth-of-type(4n+3) .link {
    background-color: $royal-blue-color;
  }

  &:nth-of-type(4n) .link {
    background-color: $lavender-color;
  }

}
</style>
