<template>
  <div class='cta-button'>

    <a v-if='isExternalLink(href)'
       class='cta'
       :href='href'
       target='_blank'><slot></slot></a>

    <router-link v-else
                 class='cta'
                 :to='href'><slot></slot></router-link>

  </div>
</template>


<script>

export default {
  name: 'CTAButton',
  props: [
    'href',
  ],
  methods: {
    isExternalLink: function(url) {
      if (url.startsWith('http')) {
        return true;
      }
      return false;
    }
  },
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.cta-button {
  
  .cta {
    background-color: #FFF;
    border: 6px solid blue;
    border-image: $blue-green-gradient;
    border-image-slice: 4;

    box-shadow: $small-box-shadow;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;

    font-size: 22px;
    font-weight: $font-bold-weight;
    text-align: center;

    cursor: pointer;

    display: block;
    margin-left: auto;
    margin-right: auto;

    &:link,
    &:visited {
      color: #000;
      text-decoration: none;
    }

    &:hover {
      color: $lavender-color;
      border-image: $pink-purple-gradient;
      border-image-slice: 4;
      outline: none;
    }

    &:active {
      color: $black-color;
      border: 6px solid #000;
      border-image: none;
      outline: none;
    }
  }

}

</style>
