<template>
  <div class='page'>
    <nav-bar></nav-bar>

    <page-nav></page-nav>

    <slot></slot>

    <div v-if='variant === "a"'>
      <div class='shape shape--a1'></div>
      <div class='shape shape--a2'></div>
    </div>

    <div v-if='variant === "b"'>
      <div class='shape shape--b1'></div>
      <div class='shape shape--b2'></div>
    </div>

    <div v-if='variant === "c"'>
      <div class='shape shape--c1'></div>
      <div class='shape shape--c2'></div>
    </div>

    <div v-if='variant === "d"'>
      <div class='shape shape--d1'></div>
      <div class='shape shape--d2'></div>
    </div>

    <div v-if='variant === "e"'>
      <div class='shape shape--e1'></div>
      <div class='shape shape--e2'></div>
    </div>

    <div v-if='variant === "f"'>
      <div class='shape shape--f1'></div>
      <div class='shape shape--f2'></div>
    </div>

    <div v-if='variant === "g"'>
      <div class='shape shape--g1'></div>
      <div class='shape shape--g2'></div>
    </div>

    <div v-if='variant === "h"'>
      <div class='shape shape--h1'></div>
      <div class='shape shape--h2'></div>
    </div>

    <div v-if='variant === "i"'>
      <div class='shape shape--i1'></div>
      <div class='shape shape--i2'></div>
    </div>

  </div>
</template>


<script>
import NavBar from '@/components/NavBar.vue'
import PageNav from '@/components/PageNav.vue'

export default {
  name: 'Page',
  props: ['variant'],
  components: {
    PageNav,
    NavBar
  }
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.page {
  position: relative;

  @include break(small-and-medium) {
    margin-top: 60px; // Must match NavBar.vue height
  }
}


.shape {
  position: absolute;
  top: 0;
  z-index: -1;

  @include break(small) {
    width: 25px;
    height: 450px;
  }

  @include break(medium) {
    width: 60px;
    height: 550px;
  }


  @include break(large) {
    width: 180px;
    height: 650px;
  }


  &--a1 {
    left: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-a1-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-a1-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-a1-large.svg");
    }
  }


  &--a2 {
    right: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-a2-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-a2-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-a2-large.svg");
    }
  }


  &--b1 {
    left: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-b1-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-b1-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-b1-large.svg");
    }
  }


  &--b2 {
    right: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-b2-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-b2-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-b2-large.svg");
    }
  }


  &--c1 {
    left: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-c1-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-c1-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-c1-large.svg");
    }
  }


  &--c2 {
    right: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-c2-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-c2-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-c2-large.svg");
    }
  }


  &--d1 {
    left: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-d1-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-d1-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-d1-large.svg");
    }
  }


  &--d2 {
    right: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-d2-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-d2-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-d2-large.svg");
    }
  }


  &--e1 {
    left: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-e1-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-e1-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-e1-large.svg");
    }
  }


  &--e2 {
    right: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-e2-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-e2-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-e2-large.svg");
    }
  }


  &--f1 {
    left: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-f1-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-f1-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-f1-large.svg");
    }
  }


  &--f2 {
    right: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-f2-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-f2-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-f2-large.svg");
    }
  }
 

  &--g1 {
    left: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-g1-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-g1-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-g1-large.svg");
    }
  }


  &--g2 {
    right: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-g2-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-g2-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-g2-large.svg");
    }
  }


  &--h1 {
    left: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-h1-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-h1-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-h1-large.svg");
    }
  }


  &--h2 {
    right: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-h2-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-h2-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-h2-large.svg");
    }
  }


  &--i1 {
    left: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-i1-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-i1-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-i1-large.svg");
    }
  }


  &--i2 {
    right: 0;

    @include break(small) {
      background-image: url("../assets/page/shape-i2-small.svg");
    }

    @include break(medium) {
      background-image: url("../assets/page/shape-i2-medium.svg");
    }

    @include break(large) {
      background-image: url("../assets/page/shape-i2-large.svg");
    }
  }

}
</style>
