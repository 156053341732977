import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home.vue";
import Schedule from "./views/Schedule.vue";
import Venue from "./views/Venue.vue";
import CodeOfConduct from "./views/CodeOfConduct.vue";
import ConvinceYourBoss from "./views/ConvinceYourBoss.vue";
import PrivacyPolicy from "./views/PrivacyPolicy.vue";
import Jobs from "./views/Jobs.vue";
import WhatIsJamstack from "./views/WhatIsJamstack.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  scrollBehavior() {
    // Always scroll to top
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        title: "",
        metaTags: [
          {
            name: "Home",
            content: "The home page of JAMstack Conf"
          },
          {
            property: "og:url",
            content: "https://jamstackconf.com/"
          },
          {
            property: "og:title",
            content: "JAMstack_conf"
          },
          {
            name: "keywords",
            content: "jamstack, pwa, progressive web apps, apis, api, react, reactjs, react.js, react conf, conference, conf, workshop, netlify, github, eventbrite, webpack, freecodecamp, redux, vue, vue.js, workshop, learn to code, learn to program, learn react, san francisco, learn programming, learn javascript, learn coding, code, coding, programming, software engineer, software developer, web development, development, engineering, coding bootcamp, javascript, open source, microservices, serverless, gatsby, contentful, angular, angular.js, angularjs",
          },
          {
            property: "og:site_name",
            content: "JAMstack_conf"
          },
          {
            property: "og:description",
            content: "2-day conference in San Francisco for learning to design, develop, & deploy modern web projects without servers"
          },
          {
            name: "twitter:site",
            content: "@jamstackconf"
          },
          {
            property: "twitter:description",
            content: "Full day conference in San Francisco for learning to design, develop, & deploy modern web projects without servers"
          },
          {
            content: "on",
            name: "twitter:widgets:csp"
          },
          {
            content: "@jamstackconf",
            name: "twitter:creator"
          },
          {
            content: "https://jamstackconf.com",
            name: "twitter:url"
          },
          {
            content: "@jamstackconf",
            name: "twitter:url"
          },
          {
            content: "summary_large_image",
            name: "twitter:card"
          },
          {
            content: "JAMstack_conf: Oct 29-30, 2018",
            name: "twitter:title"
          },
          {
            content: "https://s3-us-west-1.amazonaws.com/jamstack-conf-2018/jamstackconf-og-image.png",
            property: "og:image"
          },
          {
            content: "https://s3-us-west-1.amazonaws.com/jamstack-conf-2018/jamstackconf-twitter-card.png",
            property: "twitter:image"
          }
        ]
      }
    },
    {
      path: "/schedule",
      name: "Schedule",
      component: Schedule,
      meta: {
        title: "Schedule"
      }
    },
    {
      path: "/venue",
      name: "Venue",
      component: Venue,
      meta: {
        title: "Venue"
      }
    },
    {
      path: "/code-of-conduct",
      name: "CodeOfConduct",
      component: CodeOfConduct,
      meta: {
        title: "Code of Conduct"
      }
    },
    {
      path: "/convince-your-boss",
      name: "ConvinceYourBoss",
      component: ConvinceYourBoss,
      meta: {
        title: "Convince Your Boss"
      }
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
      meta: {
        title: "Privacy Policy"
      }
    },
    {
      path: "/what-is-jamstack",
      name: "WhatIsTheJamstack",
      component: WhatIsJamstack,
      meta: {
        title: "What is the JAMstack?"
      }
    },
    {
      path: "/jobs",
      name: "Jobs",
      component: Jobs,
      meta: {
        title: "Jobs"
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.find(
    r => r.meta && r.meta.metaTags
  );

  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.title}- JAMstack_conf`;
  } else {
    document.title = "JAMstack_conf";
  }

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));
  next();
});

export default router;
