<template>
  <div class='heading'>
    <div class='wrapper'>

      <div v-if='variant === "a"'>
        <div class='shape shape--a'></div>
      </div>
      <div v-else-if='variant === "b"'>
        <div class='shape shape--b'></div>
      </div>
      <div v-else-if='variant === "c"'>
        <div class='shape shape--c'></div>
      </div>
      <div v-else-if='variant === "d"'>
        <div class='shape shape--d'></div>
      </div>
      <div v-else-if='variant === "e"'>
        <div class='shape shape--e'></div>
      </div>
      <div v-else-if='variant === "f"'>
        <div class='shape shape--f'></div>
      </div>

      <h2><slot></slot></h2>
    </div>
  </div>
</template>


<script>

export default {
  name: 'Heading',
  props: ['variant'],
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.heading {

  color: $black-color;
  font-weight: $font-bold-weight;
  text-align: center;
  font-size: $font-heading-size;

  display: flex;
  justify-content: center;
  align-items: center;


  .wrapper {
    @media (max-width: 500px) {
      // Avoid horizontal scroll
      width: 100%;
    }
  }


  .shape {
    margin-left: auto;
    margin-right: auto;

    overflow: hidden;

    @include break(small-and-medium) {
      width: 500px;
      height: 130px;
    }

    @include break(large) {
      width: 760px;
      height: 130px;
    }


    @media (max-width: 500px) {
      // Avoid horizontal scroll
      width: 100%;
    }


    &--a {
      @include break(small-and-medium) {
        background-image: url("../assets/heading/shape-a-small.svg");
      }

      @include break(large) {
        background-image: url("../assets/heading/shape-a-large.svg");
      }
    }


    &--b {
      @include break(small-and-medium) {
        background-image: url("../assets/heading/shape-b-small.svg");
      }

      @include break(large) {
        background-image: url("../assets/heading/shape-b-large.svg");
      }
    }


    &--c {
      @include break(small-and-medium) {
        background-image: url("../assets/heading/shape-c-small.svg");
      }

      @include break(large) {
        background-image: url("../assets/heading/shape-c-large.svg");
      }
    }


    &--d {
      @include break(small-and-medium) {
        background-image: url("../assets/heading/shape-d-small.svg");
      }

      @include break(large) {
        background-image: url("../assets/heading/shape-d-large.svg");
      }
    }


    &--e {
      @include break(small-and-medium) {
        background-image: url("../assets/heading/shape-e-small.svg");
      }

      @include break(large) {
        background-image: url("../assets/heading/shape-e-large.svg");
      }
    }


    &--f {
      @include break(small-and-medium) {
        background-image: url("../assets/heading/shape-f-small.svg");
      }

      @include break(large) {
        background-image: url("../assets/heading/shape-f-large.svg");
      }
    }

  }


}
</style>
