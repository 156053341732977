<template>
  <div id='hamburger'
       v-bind:class='{open: isOpen}'
       v-on:click='onClick'>
    <span class='line'></span>
    <span class='line'></span>
    <span class='line'></span>
  </div>
</template>


<script>
export default {
  name: 'HamburgerIcon',
  props: {
    isOpen: Boolean
  },
  methods: {
    onClick: function() {
      // Little redundant, but sticking with custom events only
      this.$emit('click');
    }
  }
}
</script>


<style scoped lang="scss">
#hamburger {
  box-sizing: border-box;

  width: 24px;
  height: 24px;

  padding-top: 3px;

  position: relative;

  cursor: pointer;
}

.line {
  display: block;
  width: 24px;
  height: 2px;
  margin-bottom: 6px;
  position: relative;
  
  background: #000;

  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.line:first-child {
  transform-origin: 0% 0%;
}

.line:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.open .line {
  transform: rotate(45deg) translate(-9px, -12px);
}

.open .line:nth-last-child(2) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.open .line:nth-last-child(3) {
  transform: rotate(-45deg) translate(-9px, 12px);
}
</style>
