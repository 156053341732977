<template>
  <section-container class='home-venue-section'>

    <heading id='venue' class='heading' variant='c'>{{ title }}</heading>

    <div class='columns'>
      <div class='col-1'>

        <location class='location'
          flipped='1'
          line1='JW Marriot'
          line2='San Francisco, CA'></location>

        <div v-html='copy'></div>

        <div class='cta-links'>
          <cta-button class='cta'
            href='https://book.passkey.com/go/JAMStackConference2018'>Book Hotel</cta-button>
          <router-link to='/venue'>See venue details</router-link>
        </div>

      </div>

      <div class='col-2'>
        <image-card :image='image'></image-card>
      </div>

    </div>

  </section-container>
</template>


<script>
import SectionContainer from '@/components/SectionContainer.vue'
import Heading from '@/components/Heading.vue'
import CTAButton from '@/components/CTAButton.vue'
import Location from '@/components/Location.vue'
import ImageCard from '@/components/ImageCard.vue'

export default {
  name: 'HomeVenueSection',
  props: [
    'title',
    'image',
    'copy',
  ],
  components: {
    Heading,
    SectionContainer,
    'cta-button': CTAButton,
    Location,
    ImageCard,
  },
}
</script>


<style scoped lang='scss'>
@import "../../css/theme";

.home-venue-section {

  .heading {
    margin-bottom: 50px;
  }


  .columns {
    @include break(small-and-medium) {
      display: flex;
      flex-direction: column-reverse;
    }

    @include break(large) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }


  .col-1 {
    @include break(small-and-medium) {
      text-align: center;
      margin-bottom: 40px;

      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
    }

    @include break(large) {
      text-align: right;

      width: 50%;
      padding-right: 20px;
    }
  }


  .col-2 {
    @include break(small-and-medium) {
      margin-bottom: 60px;

      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    @include break(large) {
      width: 50%;
      padding-left: 20px;
    }
  }


  .location {
    @include break(small-and-medium) {
      margin-bottom: 50px;
      width: 260px;
      margin-left: auto;
      margin-right: auto;

      position: relative;
      left: -40px;
    }

    @include break(large) {
      margin-bottom: 30px;
    }
  }


  .cta-links {
    @include break(large) {
      margin-top: 50px;

      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-start;
    }
  }


  .cta {
    @include break(small-and-medium) {
      margin-left: auto;
      margin-right: auto;
      max-width: 270px;

      margin-top: 50px;
      margin-bottom: 40px;
    }

    @include break(large) {
      margin-left: 40px;
    }

  }

}
</style>
