<template>
  <div class='speaker-card' :class="{tiny: tiny}">

    <img v-bind:src='img'/>

    <div class='card'>
      <div class='content-wrapper'>
        <div class='content'>
          <div class='name'>{{name}}</div>
          <div class='title'>
            <span class='job'>{{job}}</span>
            <span v-if='company' class='title-separator'>/</span>
            <span v-if='company' class='company'>{{company}}</span> 
          </div>
          <slot></slot>
        </div>
      </div>

      <div v-if='mc' class='mc-icon'></div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'SpeakerCard',
  props: {
    name: String,
    img: String,
    job: String,
    company: String,
    tiny: String,
    mc: String,
  },
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.speaker-card {

  img {
    display: block;
    width: 160px;
    height: 160px;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: -60px;

    position: relative;
    z-index: 2;
  }


  .card {
    background-color: #FFF;
    box-shadow: 0 6px 34px rgba(83, 40, 255, .15);
    position: relative;
    z-index: 1;
  }


  .mc-icon {
    width: 54px;
    height: 36px;
    position: absolute;
    top: 10px;
    right: 10px;

    background-image: url('../assets/speaker-card/mc-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
  }


  .content-wrapper {
    padding-top: 55px;
    padding-bottom: 40px;
  }


  .content {
    text-align: center;

    margin-top: 25px;

    padding-left: 30px;
    padding-right: 30px;
  }


  .name {
    color: $black-color;
    font-weight: $font-bold-weight;
    font-size: $font-medium-size;
  }


  .title {
    color: $dark-purple-color;
    font-weight: $font-normal-weight;
    font-size: $font-small-size;

    margin-top: 12px;
  }


  .title-separator {
    margin-left: 5px;
    margin-right: 5px;
  }


  .company {
    font-weight: 700;
    font-size: 15px;
  }



  &.tiny {
    img {
      width: 120px;
      height: 120px;
    }

    .content-wrapper {
      padding-top: 35px;
      padding-bottom: 30px;
    }

    .content {
      padding-left: 20px;
      padding-right: 20px;
    }


    .name {
      color: $black-color;
      font-weight: $font-bold-weight;
      font-size: $font-text-size;
    }


    .title {
      margin-top: 6px;
      font-size: 12px;
    }

    .company {
      font-size: 12px;
    }


  }

}
</style>
