<template>
  <page variant='h' class='what-is-jamstack'>

    <h1 class='heading'>{{ title }}</h1>

    <div class='intro' v-html='intro'></div>


    <card-container class='card-wrapper card-wrapper--less-v-margin' variant='b'>

      <div class='sections'>

        <div class='section'>

          <h2 class='subheading'>{{ howItWorks.title }}</h2>

          <div v-html='howItWorks.beforeCopy'></div>

          <picture class='how-it-works-image'>
             <source media='(min-width: 481px)'
                :srcset='howItWorks.image'/>
             <source media='(max-width: 480px)'
                :srcset='howItWorks.imageMobile'/>
             <img src='howItWorks.image'/>
           </picture>

          <div v-html='howItWorks.afterCopy'></div>

        </div>

      </div>
    </card-container>


    <card-container class='card-wrapper card-wrapper--less-v-margin' noShadow='1'>

      <div class='videos'>

        <div v-for='video in videoList'
             class='video'
             :key='video.link'>

          <a :href='video.link' target='_blank'><img :src='video.thumb'/></a>

          <a :href='video.link' class='label' target='_blank'>{{ video.label }}</a>

        </div>

      </div>

      <a href='https://www.heavybit.com/library/podcasts/jamstack-radio/'
         target='_blank'>
        <picture class='radio-banner'>
           <source media='(min-width: 481px)'
              srcset='/images/uploads/whats-jamstack-radio.jpg'/>
           <source media='(max-width: 480px)'
              srcset='/images/uploads/whats-jamstack-radio-mobile.jpg'/>
           <img src='/images/uploads/whats-jamstack-radio.jpg'/>
         </picture>
      </a>



    </card-container>


    <card-container class='card-wrapper' variant='a'>

      <div class='sections'>

        <div class='section'>

          <h2 class='subheading'>{{ followTitle }}</h2>

          <a class='twitter-timeline'
             data-tweet-limit='2'
             data-chrome='noheader noborders'
             href='https://twitter.com/jamstackconf?ref_src=twsrc%5Etfw'>Tweets by jamstackconf</a>

        </div>

      </div>
    </card-container>


    <card-container class='card-wrapper' variant='b'>

      <div class='sections'>

        <div class='section'>

          <h2 class='subheading'>{{ resources.title }}</h2>

          <ul class='resources'>
            <li v-for='resource in resources.list'
                 class='resource'
                 :key='resource.link'>
              <a :href='resource.link' target='blank'>{{ resource.label }}</a>
            </li>
          </ul>

        </div>

      </div>
    </card-container>

    <buy-now-section></buy-now-section>
    <footer-section></footer-section>

  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import CardContainer from '@/components/CardContainer.vue'
import BuyNowSection from '@/components/BuyNowSection.vue'
import FooterSection from '@/components/FooterSection.vue'

import whatIsJamstackContent from '@/content/what-is-jamstack.json'

export default {
  name: 'CodeOfConduct',
  components: {
    Page,
    CardContainer,
    BuyNowSection,
    FooterSection,
  },
  mounted() {
    // Add twitter embed script
    this.twitterScript = document.createElement('script');
    this.twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    this.twitterScript.setAttribute('async', '1');
    document.head.appendChild(this.twitterScript)
  },
  beforeDestroy() {
    // Remove twitter embed script
    document.head.removeChild(this.twitterScript)
  },
  data: function() {
    return whatIsJamstackContent
  },
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.what-is-jamstack {

  .heading {
    font-size: $font-alternate-heading-size;
    text-align: center;

    margin-top: 70px;
    margin-bottom: 40px;

    padding-left: 20px;
    padding-right: 20px;

    @include break(small-and-medium) {
      margin-top: 110px;
    }
  }


  .heading {
    padding-left: 50px;
    padding-right: 50px;
  }


  .intro {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;

    margin-bottom: 90px;

    padding-left: 50px;
    padding-right: 50px;
  }


  .subheading {
    font-size: $font-subheading-size;
    font-weight: $font-bold-weight;
    text-align: center;

    margin-bottom: 40px;
  }


  h3 {
    font-size: $font-bigger-size;

    @include break(small) {
      font-size: $font-text-size;
    }
  }


  .card-wrapper {
    margin-bottom: 120px;
  }


  .card-wrapper--less-v-margin {
    margin-bottom: 60px;
  }


  .sections {
    font-size: $font-small-size;

    @include break(small-and-medium) {
      margin-top: -40px;
    }
  }


  .how-it-works-image img {
    display: block;
    width: 100%;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;

    @include break(small) {
      width: 120%;
      max-width: 320px;
      margin-left: -20px;
    }
  }


  .videos {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }


  .video {
    max-width: 312px;
    height: 260px;

    margin-bottom: 20px;

    @include break(medium-and-large) {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 60px;
    }

    .label {
      font-size: $font-small-size;
    }

    img {
      width: 100%;
      height: auto;
    }
  }


  .radio-banner {
    @include break(small) {
      max-width: 244px;
    }

    @include break(medium-and-large) {
      max-width: 734px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }


}
</style>
