<template>
  <div class='location' :class='{ flipped: flipped }'>

    <div class='icon-wrapper'>
      <img class='icon' src='@/assets/location/icon.svg'/>
    </div>
    <div class='text'>
      <div class='line1'>{{ line1 }}</div>
      <div class='line2'>{{ line2 }}</div>
      <div class='line3'>{{ line3 }}</div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'Location',
  props: [
    'line1',
    'line2',
    'line3',
    'flipped',
  ],
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.location {

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.flipped {
    text-align: right;
    flex-direction: row-reverse;
  }

  &.flipped .text {
    margin-right: 15px;
    margin-left: 0;
  }

  .text {
    margin-left: 15px;
  }

  .line1 {
    font-weight: $font-bold-weight;
  }

}
</style>
