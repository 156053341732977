<template>
  <card-container class='schedule-intro-section' variant='a'>

    <div class='days'>
      <div class='day'>
        <div class='day__heading'>Monday, Oct 29th</div>
        <div class='day__row'>
          Agency Day <span class='day__extra'>(Day)</span>
        </div>
        <div class='day__row'>
          Welcome Reception <span class='day__extra'>(Evening)</span>
        </div>
      </div>

      <div class='day'>
        <div class='day__heading'>Tuesday, Oct 30th</div>
        <div class='day__row'>
          Conference <span class='day__extra'>(Day)</span>
        </div>
        <div class='day__row'>
          After Party <span class='day__extra'>(Evening)</span>
        </div>
      </div>
    </div>

    <cta-button class='cta' href='https://ti.to/netlify/jamstackconf'>Get Tickets</cta-button>

  </card-container>
</template>


<script>
import CardContainer from '@/components/CardContainer.vue'
import CTAButton from '@/components/CTAButton.vue'

export default {
  name: 'ScheduleIntroSection',
  components: {
    CardContainer,
    'cta-button': CTAButton,
  },
}
</script>


<style scoped lang='scss'>
@import "../../css/theme";

.schedule-intro-section {

  margin-bottom: 120px;

  .days {
    margin-bottom: 60px;

    @include break(large) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }


  .day {
    @include break(small-and-medium) {
      width: 200px;
      margin-left: auto;
      margin-right: auto;
    }

    &:first-of-type {
      @include break(small-and-medium) {
        margin-bottom: 40px;
      }
    }
  }


  .day__heading {
    font-size: $font-text-size;
    font-weight: $font-bold-weight;

    padding-top: 4px;
    padding-bottom: 4px;

    border-bottom: 1px solid $light-gray-rule-color;
  }


  .day__row {
    font-size: $font-small-size;

    padding-top: 4px;
    padding-bottom: 4px;

    border-bottom: 1px solid $light-gray-rule-color;
  }


  .day__extra {
    font-size: $font-extra-small-size;
  }


  .cta {
    margin-left: auto;
    margin-right: auto;
    max-width: 270px;
  }

}
</style>
