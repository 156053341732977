<template>
  <div class='schedule-item'>

    <div class='time'>{{ event.time }}</div>

    <div class='info'>

      <div class='title-wrapper'>
        <span class='title'>{{ event.title }}</span>
        <span v-if='event.type === "food"'>
          <img class='icon' src='@/assets/schedule-item/food.svg'/>
        </span>
        <span v-if='event.type === "lightning"'>
          <img class='icon' src='@/assets/schedule-item/lightning.svg'/>
        </span>
        <span v-if='event.type === "remarks"'>
          <img class='icon' src='@/assets/schedule-item/remarks.svg'/>
        </span>
        <span v-else-if='event.type === "talk"'>
          <img class='icon' src='@/assets/schedule-item/talk.svg'/>
        </span>
        <span v-if='event.type === "workshop"'>
          <img class='icon' src='@/assets/schedule-item/workshop.svg'/>
        </span>
        <span v-if='event.type === "break"'>
          <img class='icon' src='@/assets/schedule-item/break.svg'/>
        </span>
      </div>


      <div class='speaker' v-if='event.speaker'>
        <div class='name' :class='event.type'>
          {{ event.speaker.name }}
        </div>
        |
        <div class='company'>
          {{ event.speaker.company }}
        </div>
      </div>

      <div class='abstract'
        v-if='event.abstract'
        v-html='event.abstract'>
      </div>


      <transition name='slide-down'>
        <div class='details'
          v-if='showDetails'
          v-html='event.details'>
        </div>
      </transition>


      <div class='view-details-btn'
           v-if='event.details'
           v-on:click='onToggleDetails'>{{ toggleDetailsLabel }}</div>

    </div>

  </div>
</template>


<script>
export default {
  name: 'ScheduleItem',
  props: [
    'event',
  ],
  data: function() {
    return {
      isOpen: false
    }
  },

  computed: {
    showDetails: function() {
      return (this.isOpen && this.event.details && this.event.details !== "");
    },

    toggleDetailsLabel: function() {
      if (this.isOpen) {
        return "Hide Full Abstract";
      } else {
        return "View Full Abstract";
      }
    }
  },

  methods: {
    onToggleDetails: function() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.schedule-item {
  position: relative;

  .time {
    font-size: $font-small-size;

    @include break(small-and-medium) {
      margin-bottom: 4px;
    }

    @include break(large) {
      position: absolute;
      text-align: right;
      width: 100px;
      top: 0;
      left: -115px;
    }
  }


  .title-wrapper {
    @include break(small-and-medium) {
      margin-bottom: 5px;
    }
  }


  .title {
    font-size: $font-text-size;
    font-weight: $font-bold-weight;

    margin-right: 10px;
  }


  .icon {
    display: inline-block;
    vertical-align: middle;
  }


  .speaker {
    font-size: $font-small-size;
    font-weight: $font-bold-weight;

    display: flex;
    justify-content: flex-start;
    align-items: center;


    .name {
      margin-right: 6px;

      &.talk {
        color: $pink-text-color;
        
      }

      &.remarks {
        color: $royal-blue-color;
      }

      &.lightning {
        color: $lavender-color;
      }
    }

    .company {
      margin-left: 6px;
    }
  }


  .abstract,
  .details {
    margin-top: -5px;
    font-size: $font-small-size;
  }


  .view-details-btn {
    text-align: right;
    font-size: 12px;
    color: $pink-text-color;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }


  .details {
    transition-property: max-height, opacity;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;


    &.slide-down-enter {
      opacity: 0;
      max-height: 0;
    }

    &.slide-down-enter-to {
      opacity: 1;
      max-height: 600px;
    }

    &.slide-down-leave {
      opacity: 1;
      max-height: 600px;
    }

    &.slide-down-leave-to {
      opacity: 0;
      max-height: 0;
    }
  }

}
</style>


<style lang='scss'>
@import "../css/theme";

// Scoped CSS doesn't work with HTML embeded via the `v-html` directive. So, we
// add it as a global style here, "scoped" using used a descendant selector.

.schedule-item {
  h4 {
    font-size: $font-text-size;
    font-weight: $font-bold-weight;

    margin-bottom: -10px;
  }
}
</style>
