<template>
  <section-container class='home-speakers-section'>

    <heading id='speakers' class='heading' variant='a'>{{ title }}</heading>

    <div class='intro' v-html='introCopy'></div>

    <div class='speakers'>
      <div v-for='speaker in speakerList'
           class='speaker'
           :key='speaker.name'>
        <speaker-card v-bind:name='speaker.name'
              :img='speaker.photo'
              :job='speaker.job'
              :company='speaker.company'>
        </speaker-card>
      </div>

    </div>


    <div class='speaker-mc-wrapper'>
      <speaker-card class='speaker-mc' mc='1'
            :name='speakerMC.name'
            :img='speakerMC.photo'
            :job='speakerMC.job'
            :company='speakerMC.company'>
      </speaker-card>
    </div>

    <heading id='lightningSpeakers' class='heading-small' variant='b'>Lightning Speakers</heading>

    <div class='lightning-speakers'>
      <div v-for='speaker in lightningSpeakers'
           class='speaker'
           :key='speaker.name'>
        <speaker-card v-bind:name='speaker.name'
              :img='speaker.photo'
              :job='speaker.job'
              :company='speaker.company'
              tiny='1'>
        </speaker-card>
      </div>
    </div>


    <div class='extra' v-html='extraCopy'></div>

  </section-container>
</template>


<script>
import SectionContainer from '@/components/SectionContainer.vue'
import SpeakerCard from '@/components/SpeakerCard.vue'
import Heading from '@/components/Heading.vue'

export default {
  name: 'HomeSpeakersSection',
  props: [
    'title',
    'introCopy',
    'speakerMC',
    'speakerList',
    'lightningTitle',
    'lightningSpeakers',
    'extraCopy',
  ],
  components: {
    Heading,
    SectionContainer,
    SpeakerCard,
  },
}
</script>


<style scoped lang='scss'>
@import "../../css/theme";

.home-speakers-section {

  .heading {
    margin-bottom: 20px;
  }

  .heading-small {
    margin-bottom: 50px;
    font-size: 20px;
  }

  .intro {
    text-align: center;

    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    margin-bottom: 50px;
  }


  .extra {
    text-align: center;

    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }


  .speakers {

    @include break(small-and-medium) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > * {
        /* All <speaker-cards> */
        width: 350px;

        margin-bottom: 45px;
      }
    }


    @media (max-width: 480px) {
      & > * {
        width: 290px;
      }
    }


    @include break(large) {
      margin-bottom: 40px;

      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      & > * {
        /* All <speaker-cards> */
        width: 350px;

        margin-right: 24px;

        &:nth-of-type(3n) {
          margin-right: 0;
        }

        &:nth-of-type(3n-1) {
          position: relative;
          top: 30px;
        }
      }
    }

  }

  .speaker {
    @include break(large) {
      width: 31%;
      margin-bottom: 60px;
    }
  }


  .lightning-speakers {

    @include break(small-and-medium) {
      margin-bottom: 40px;

      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      & > * {
        /* All <speaker-cards> */
        width: 40%;

        margin-right: 10px;
        margin-left: 10px;

        margin-bottom: 45px;
      }
    }


    @media (max-width: 480px) {
      & > * {
        width: 290px;
      }
    }


    @include break(large) {
      margin-bottom: 0;

      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      & > * {
        /* All <speaker-cards> */
        width: 300px;

        margin-left: 15px;
        margin-right: 15px;
      }
    }

  }

  .speaker-mc-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .speaker-mc {

    @include break(small-and-medium) {
      width: 350px;
      margin-bottom: 60px;
    }

    @media (max-width: 480px) {
      width: 290px;
    }

    @include break(large) {
      width: 440px;
      margin-bottom: 80px;
    }

  }


}

</style>
