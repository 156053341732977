import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { VueResponsiveComponents } from "vue-responsive-components"

import VueResource from 'vue-resource'
Vue.use(VueResource)
Vue.http.options.emulateJSON = true


// Using `VueResponsiveComponents` instead of media queries (for NavBar)!
Vue.use(VueResponsiveComponents)

Vue.config.productionTip = false


let data = {
  testContent: 'Hello there, this is test content'
}


const root = new Vue({
  router,
  render: h => h(App),
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  },
  data: data
})



document.addEventListener('DOMContentLoaded', function() {
  root.$mount('#app')
})
