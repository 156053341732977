<template>
  <page variant='a'>

    <home-hero-section
      :date='hero.date'
      :location='hero.location'
      :abstract='hero.abstract'
      :detailTitle='hero.detailTitle'
      :detailCopy='hero.detailCopy'
      :jamstackImage='hero.jamstackImage'></home-hero-section>

    <div class='sections'>
      <!-- Need .sections wrapper for cycling separators in
           SectionContainer.vue -->

      <home-speakers-section
        :title='speakers.title'
        :introCopy='speakers.introCopy'
        :speakerMC='speakers.speakerMC'
        :speakerList='speakers.speakerList'
        :lightningSpeakers='speakers.lightningSpeakers'
        :extraCopy='speakers.extraCopy'></home-speakers-section>

      <home-activities-section
        :title='activities.title'
        :activityList='activities.activityList'></home-activities-section>

      <home-venue-section
        :title='venue.title'
        :copy='venue.copy'
        :image='venue.image'></home-venue-section>

      <home-hackathon-section
        :title='hackathon.title'
        :copy='hackathon.copy'
        :image='hackathon.image'></home-hackathon-section>

      <home-sponsors-section
        :title='sponsors.title'
        :copy='sponsors.copy'
        :tierList='sponsors.tierList'></home-sponsors-section>

    </div>

    <buy-now-section></buy-now-section>

    <footer-section></footer-section>
  </page>
</template>

<script>
import Page from '@/components/Page.vue'

import HomeHeroSection from '@/components/home-sections/HomeHeroSection.vue'
import HomeSpeakersSection from '@/components/home-sections/HomeSpeakersSection.vue'
import HomeActivitiesSection from '@/components/home-sections/HomeActivitiesSection.vue'
import HomeVenueSection from '@/components/home-sections/HomeVenueSection.vue'
import HomeHackathonSection from '@/components/home-sections/HomeHackathonSection.vue'
import HomeSponsorsSection from '@/components/home-sections/HomeSponsorsSection.vue'
import BuyNowSection from '@/components/BuyNowSection.vue'
import FooterSection from '@/components/FooterSection.vue'

import homeContent from '@/content/home.json'

export default {
  name: 'Home',
  components: {
    Page,
    HomeHeroSection,
    HomeSpeakersSection,
    HomeActivitiesSection,
    HomeVenueSection,
    HomeHackathonSection,
    HomeSponsorsSection,
    BuyNowSection,
    FooterSection,
  },
  data: function() {
    return homeContent
  },
}
</script>
