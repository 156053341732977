<template>
  <section-container class='home-hackathon-section'>

    <!-- NOTE: This is almost a straight copy of HomeVenueSection.vue. We
         should probably refactor into a reusable component. -->

    <heading id='hackathon' class='heading' variant='d'>{{ title }}</heading>

    <div class='columns'>
      <div class='col-1'>
        <image-card :image='image'></image-card>
      </div>

      <div class='col-2'>

        <location class='location'
          line1='GitHub HQ'
          line2='San Francisco, CA'></location>

        <div v-html='copy'></div>

      </div>

    </div>

  </section-container>
</template>


<script>
import SectionContainer from '@/components/SectionContainer.vue'
import Heading from '@/components/Heading.vue'
import CTAButton from '@/components/CTAButton.vue'
import Location from '@/components/Location.vue'
import ImageCard from '@/components/ImageCard.vue'

export default {
  name: 'HomeHackathonSection',
  props: [
    'title',
    'image',
    'copy',
  ],
  components: {
    Heading,
    SectionContainer,
    'cta-button': CTAButton,
    Location,
    ImageCard,
  },
}
</script>


<style scoped lang='scss'>
@import "../../css/theme";

.home-hackathon-section {

  .heading {
    margin-bottom: 50px;
  }


  .columns {
    @include break(large) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }


  .col-1 {
    @include break(small-and-medium) {
      text-align: center;
      margin-bottom: 60px;

      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
    }

    @include break(large) {
      text-align: right;

      width: 50%;
      padding-right: 20px;
    }
  }


  .col-2 {
    @include break(small-and-medium) {
      margin-bottom: 60px;

      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    @include break(large) {
      width: 50%;
      padding-left: 20px;
    }
  }


  .location {
    @include break(small-and-medium) {
      margin-bottom: 50px;
      width: 260px;
      margin-left: auto;
      margin-right: auto;

      position: relative;
      left: 20px;
    }

    @include break(large) {
      margin-bottom: 30px;
    }
  }


  .cta {
    margin-left: auto;
    margin-right: auto;
    max-width: 270px;

    @include break(small-and-medium) {
      margin-top: 50px;
    }

    @include break(large) {
      margin-top: 30px;
      float: right;
    }
  }

}
</style>
