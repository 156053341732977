<template>
  <page variant='e' class='convince-your-boss'>

    <h1 class='heading'>Convince Your Boss</h1>

    <div class='intro'>
      <p>Eager to attend but need to convince the higher-ups? We've prepared a
      template describing many of the reasons why you and your company will
      benefit from attending the conference.</p>

      <p>You can also <a href='mailto:info@jamstackconf.com'>email us</a> with
      inquiries on group discounts or any other questions!</p>
    </div>


    <card-container class='wrapper' variant='a'>

      <div class='greeting'>Dear __(amazing boss)__,</div>

      <div class='email'>

        <p>I'd like your permission to attend JAMStack_conf (jamstackconf.com)
        October 29-30 in San Francisco. I'm hoping you can help make it
        possible.</p>

        <p>While there are lots of developer-oriented conferences, JAMStack_conf
        uniquely tackles the exact topics my team has been working to get our
        heads around: modern web deployment workflows and practices. This is
        the perfect chance to tune-up (and level-up) all the processes and tech
        we are using to deploy, publish, and build our sites and apps.</p>

        <p>I’ll return from the event with a bunch of notes and steps we can
        implement immediately to make all our web properties more performant
        and more secure.  It's the best two days I've seen for advancing my own
        skills and bringing a ton of best practices back to my team. As they
        say, this conference is “where frameworks and API services combine to
        create real, production results.”</p>


        <h3>Advantages for our company:</h3>

        <p>The things I’ll learn over the two-day conference will help us:</p>

        <ul>
          <li>Build new processes for faster, more predictable deployments and
            publishing</li>

          <li>Increase the performance of all our websites</li>

          <li>Reduce infrastructure requirements and lower costs</li>

          <li>Increase web security and reduce our surface area for attacks</li>

          <li>Deploy our websites globally and without single points of
            failure</li>
        </ul>

        <h3>Advantages for me and my development:</h3>

        <p>For my own growth as a developer, I’m excited because:</p>

        <ul>
          <li>There’s a fantastic speaker lineup of the experts who work on the
            frameworks and technologies we use daily.</li>

          <li>The intimacy of this conference will allow me to interact with
            the speakers and ask questions.</li>

          <li>I’ll also learn from 300 other developer attendees focused
            on building for the web. I can ask questions, learn best-practices
            from their projects, and create connections.</li>

          <li>I'll learn more about the deployment and front-end technologies
            that have taken the leading role in modern web development. This is
            a big shift and an important time to learn about it.</li>

        </ul>

        <h3>Here’s a breakdown of expenses</h3>

        <pre class='desktop-only'>Registration fee................................$499
Hotel, x2 nights................................$598
Flight..........................................$399
Total.........................................$1,496</pre>

        <pre class='tablet-only'>Registration fee..............$499
Hotel, x2 nights..............$598
Flight........................$399
Total.......................$1,496</pre>

        <pre class='mobile-only'>Registration fee.....$499
Hotel, x2 nights.....$598
Flight...............$399
Total..............$1,496</pre>

        <p>I'll bring direct, actionable knowledge back to my work,
        including notes and steps we can implement immediately to make all our
        web properties more performant and more secure. I can make an internal
        presentation discussing what I learned and how it can be applied to
        improve our project infrastructure. The conference will also provide
        us with a package of videos and slides from all the talks that I can bring
        back to the team.</P>

        <p>Please take a look at the website and the speaker lineup. Hopefully,
        you are as excited about it as I am. Seems like a great event and a
        great investment in training one of your favorite employees.</p>

        <p>Thanks!</p>

      </div>

      <div class='farewell'>
        Sincerely,<br/>
        A dev with a passion for learning
       </div>
    </card-container>


    <buy-now-section></buy-now-section>

    <footer-section></footer-section>

  </page>
</template>


<script>
import Page from '@/components/Page.vue'
import CardContainer from '@/components/CardContainer.vue'
import BuyNowSection from '@/components/BuyNowSection.vue'
import FooterSection from '@/components/FooterSection.vue'

export default {
  name: 'ConvinceYourBoss',
  components: {
    Page,
    CardContainer,
    BuyNowSection,
    FooterSection,
  }
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.convince-your-boss {

  .heading {
    font-size: $font-alternate-heading-size;
    text-align: center;

    margin-top: 70px;
    margin-bottom: 40px;

    padding-left: 40px;
    padding-right: 40px;


    @include break(small-and-medium) {
      margin-top: 110px;
    }
  }


  .intro {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;

    margin-bottom: 90px;

    padding-left: 40px;
    padding-right: 40px;
  }


  h3 {
    font-size: $font-bigger-size;

    @include break(small) {
      font-size: $font-text-size;
    }
  }


  .wrapper {
    margin-bottom: 150px;
  }


  .greeting {
    font-size: $font-medium-size;
    font-weight: $font-bold-weight;

    margin-bottom: 30px;


    @include break(small-and-medium) {
      margin-top: -20px;
    }

    @include break(large) {
      position: relative;
      left: -40px;
    }
  }


  .farewell {
    font-size: $font-medium-size;
    font-weight: $font-bold-weight;

    margin-top: 30px;

    @include break(small-and-medium) {
      margin-bottom: -20px;
    }

    @include break(large) {
      position: relative;
      left: -40px;
    }
  }


  .email {
    font-size: $font-small-size;
  }


  pre {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 30px;

    padding: 0;
    display: none;

    font-size: 13px;

    overflow-x: scroll;

    &.mobile-only {
      @media (max-width: 450px) {
        display: block;
      }
    }

    &.tablet-only {
      @media (min-width: 451px) and (max-width: 850px) {
        display: block;
      }
    }


    &.desktop-only {
      @media (min-width: 851px) {
        display: block;
      }
    }
  }




}
</style>
