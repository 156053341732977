
<template>
  <page variant='d' class='privacy-policy'>

    <h1 class='heading'>Privacy Policy</h1>

    <card-container class='wrapper' variant='a'>

      <div class='sections'>

        <div class='section'>

          <p>Netlify is committed to maintaining the trust of JAMstack_conf
          participants, website visitors, and subscribers to the JAMstack_conf
          newsletter. We promise to handle and store your data fairly and legally
          at all times.</p>

          <p>You have the right to view, amend, or delete the personal information
          that we hold. If you have any questions about what data we collect or how
          we use it, please let us know by emailing: <a
          href='mailto:team@netlify.com'>team@netlify.com</a></p>

        </div>

          
        <div class='section'>

          <h2>Personal information that we collect and why we collect it</h2>

          <p>We may collect personally identifiable information from you
          for the following reasons:</p>


          <h3>You signed up for our newsletter</h3>

          <p>When you complete the form to sign up for our newsletter, you’re
          agreeing to receive occasional emails about JAMstack_conf. These emails
          include information about:</p>

          <ul>
            <li>Scholarship schemes</li>
            <li>Ticket launches</li>
            <li>Speaker announcements</li>
            <li>Workshops</li>
            <li>Future JAMstack_conf announcements</li>
          </ul>

          <p>We will store your email address unless you unsubscribe from the
          mailing list. Links to unsubscribe and to update your email preferences
          can be found on every email we send you. We don’t rent, sell or trade
          email lists with other organisations and businesses for marketing
          purposes.</p>

          <p>We may use a third-party vendor to deliver these emails.</p>


          <h3>You’re attending our conference</h3>

          <p>We work with the <a href='https://ti.to' target='_blank'>Ti.to</a> platform
          to process ticket payments. Information collected by Tito is required to
          purchase a ticket for JAMstack_conf, and it includes:</p>

          <ul>
            <li>Your full name</li>
            <li>Your email address</li>
            <li>Your payment details</li>
          </ul>


          <p>Ti.to keeps a record of your name and email address so that we can
          contact you about your ticket purchase. We do not keep your payment
          details. You can read Ti.to’s privacy notice <a
            href='https://ti.to/privacy' target='_blank'>here</a>.</p>


          <h3>You visited this website</h3>

          <p>When you load this website, we collect the following information in
          the form of server logs:</p>

          <ul>
            <li>Your IP address</li>
            <li>Date and time of the request for the page load</li>
            <li>Size of the data package being transmitted within the connection</li>
            <li>Type and version of your web browser</li>
            <li>Request information like HTTP method, host name, URI, referrer, etc</li>
          </ul>

          <p>This website does not use cookies.</p>

        </div>

      </div>
    </card-container>


    <footer-section></footer-section>

  </page>
</template>

<script>
import Page from '@/components/Page.vue'
import CardContainer from '@/components/CardContainer.vue'
import FooterSection from '@/components/FooterSection.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    Page,
    CardContainer,
    FooterSection,
  }
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.privacy-policy {

  .heading {
    font-size: $font-alternate-heading-size;
    text-align: center;

    margin-top: 70px;
    margin-bottom: 80px;

    padding-left: 20px;
    padding-right: 20px;

    @include break(small-and-medium) {
      margin-top: 110px;
    }
  }


  h2 {
    font-size: $font-subheading-size;
    margin-top: 40px;

    @include break(small) {
      font-size: $font-bigger-size;
    }
  }


  h3 {
    font-size: $font-bigger-size;

    @include break(small) {
      font-size: $font-text-size;
    }
  }


  .wrapper {
    margin-bottom: 150px;
  }

  .sections {
    font-size: $font-small-size;

    @include break(small-and-medium) {
      margin-top: -40px;
    }
  }

}
</style>
