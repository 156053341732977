<template>
  <card-container class='schedule-reception-section' variant='a'>

    <h2 class='heading'>{{ title }}</h2>

    <div class='date'>{{ date }}</div>

    <div class='text'>
      <div class='copy' v-html='copy'></div>
    </div>

    <div class='schedule'>
      <div v-for='event in schedule'
           class='event'
           :key='event.time'>

        <schedule-item :event='event'></schedule-item>

      </div>
    </div>

    <div class='extra'>{{ extra }}</div>

  </card-container>
</template>


<script>
import CardContainer from '@/components/CardContainer.vue'
import ScheduleItem from '@/components/ScheduleItem.vue'

export default {
  name: 'ScheduleReceptionSection',
  props: [
    'title',
    'date',
    'copy',
    'schedule',
    'extra',
  ],
  components: {
    CardContainer,
    ScheduleItem,
  },
}
</script>


<style scoped lang='scss'>
@import "../../css/theme";

.schedule-reception-section {

  margin-bottom: 120px;

  .heading {
    font-size: $font-alternate-heading-size;
    font-weight: $font-bold-weight;
    text-align: center;

    margin-bottom: 22px;
  }

  .text {
    @include break(small-and-medium) {
      margin-left: auto;
      margin-right: auto;
      max-width: 430px;
    }
  }

  .date {
    font-size: $font-small-size;
    text-align: center;

    margin-bottom: 30px;
  }


  .copy {
    font-size: $font-small-size;

    margin-bottom: 50px;
  }

  .event {
    margin-bottom: 50px;

    @include break(large) {
      position: relative;
      left: 50px;

      // This is to get rid of a bad line break on an icon for one of the talks
      padding-right: 20px;
    }
  }

  .extra {
    color: $light-gray-color;
    font-size: $font-small-size;
    text-align: center;
  }

}
</style>
