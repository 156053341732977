<template>
  <section-container class='home-sponsors-section'>

    <heading id='sponsors' class='heading' variant='e'>{{ title }}</heading>

    <div class='intro' v-html='copy'></div>

    <div class='sponsors'>

      <div class='sponsors-card-wrapper'
           v-for='tier in tierList'
           :key='tier.label'>

        <div class='title'>{{ tier.label }}</div>
        <div class='sponsors-card'>
          <a v-for='sponsor in tier.sponsorList'
             :key='sponsor.link'
             :href='sponsor.link'
             class='sponsors-card__link'
             target='_blank'>
            <img class='sponsors-card__img'
                 :src='sponsor.image'/>
          </a>
        </div>
      </div>

    </div>


  </section-container>
</template>


<script>
import SectionContainer from '@/components/SectionContainer.vue'
import Heading from '@/components/Heading.vue'

export default {
  name: 'HomeSponsorsSection',
  props: [
    'title',
    'tierList',
    'copy',
  ],
  components: {
    Heading,
    SectionContainer,
  },
}
</script>


<style scoped lang='scss'>
@import "../../css/theme";

.home-sponsors-section {

  .heading {
    margin-bottom: 20px;
  }


  .intro {
    text-align: center;

    margin-left: auto;
    margin-right: auto;
    max-width: 500px;

    margin-bottom: 50px;
  }


  .sponsors {
    padding-bottom: 20px;

    margin-bottom: -60px;
  }


  .sponsors-card-wrapper {
    width: 100%;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;


    position: relative;
    z-index: 100;


    .title {
      color: $light-gray-color;
      font-weight: $font-bold-weight;
      font-size: $font-bigger-size;
      line-height: 1.3;
      text-align: center;


      @media (max-width: 929px) {
        margin-bottom: 30px;
      }


      @media (min-width: 930px) {
        position: absolute;
        top: 20px;
        left: -150px;
        width: 120px;
        text-align: right;
      }
    }
  }


  .sponsors-card {
    background-color: #FFFFFF;
    box-shadow: $small-box-shadow;

    padding: 10px;


    @include break(large) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }


    &__link {
      display: block;

      margin-top: 20px;
      margin-bottom: 20px;
    }


    &__img {
      display: block;
      width: 270px;
      height: auto;

      @include break(small-and-medium) {
        margin-left: auto;
        margin-right: auto;
      }

      @media (max-width: 450px) {
        width: 190px;
      }
    }

  }




}
</style>
