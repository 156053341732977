<template>
  <hero-container class='home-hero-section'>

    <div class='top'>

      <img class='mobile-logo' src='@/assets/jamstack-conf-logo.svg'/>

      <div class='logistic-info'>
        <div class='date'>{{ date }}</div>
        <div class='location'>{{ location }}</div>
      </div>
      <div class='abstract' v-html='abstract'></div>

      <cta-button class='cta' href='https://jamstackconf.com'>It's over!</br>Go to this year's events</cta-button>

    </div>

    <div class='bottom'>

      <div class='shape-1'></div>

      <div class='detail'>
        <h3 class='detail-title'>{{ detailTitle }}</h3>
        <div class='detail-copy' v-html='detailCopy'></div>
      </div>

      <div class='image-wrapper'>
        <img class='image'
             :src='jamstackImage'/>
      </div>
    </div>

  </hero-container>
</template>



<script>
import HeroContainer from '@/components/HeroContainer.vue'
import CTAButton from '@/components/CTAButton.vue'

export default {
  name: 'HomeHeroSection',
  props: [
    'date',
    'location',
    'abstract',
    'detailTitle',
    'detailCopy',
    'jamstackImage',
  ],
  components: {
    HeroContainer,
    'cta-button': CTAButton,
  }
}
</script>


<style scoped lang='scss'>
@import "../../css/theme";

.home-hero-section {

  .top {
    margin-left: auto;
    margin-right: auto;
    max-width: 620px;

    margin-bottom: 180px;
  }


  .mobile-logo {
    // NOTE: This is is a special case for the home page. The <page-nav> always
    // disappears on mobile, which is usually fine because other pages have
    // their own headings. However, the home page does *not* have its own
    // heading, so we have to add it here.

    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;


    @include break(small) {
      width: 220px;
      height: auto;

      margin-bottom: 40px;
    }

    @include break(medium) {
      width: 300px;

      margin-bottom: 40px;
    }

    @include break(large) {
      display: none;
    }

  }


  .logistic-info {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
    white-space: nowrap;


    @include break(small) {
      flex-direction: column;
      margin-bottom: 40px;
    }

    @include break(medium) {
      margin-bottom: 40px;
    }

  }


  .date {
    color: $purple-color;
    font-weight: $font-medium-weight;
    font-size: $font-medium-size;


    @include break(small) {
      font-size: $font-bigger-size;
    }

    @include break(medium) {
      margin-right: 20px;
    }

    @include break(large) {
      margin-right: 20px;
    }
  }


  .location {
    color: $royal-blue-color;
    font-weight: $font-medium-weight;
    font-size: $font-medium-size;

    @include break(small) {
      font-size: $font-bigger-size;
    }
  }


  .abstract {
    text-align: center;

    @include break(small) {
      margin-bottom: 50px;
    }

    @include break(medium) {
      margin-bottom: 50px;
    }

    @include break(large) {
      margin-bottom: 40px;
    }
  }


  .cta {
    margin-left: auto;
    margin-right: auto;
    max-width: 330px;
  }



  .bottom {
    position: relative;

    @include break(large) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }


  .detail {
    @include break(small) {
      text-align: center;
      margin-bottom: 30px;
    }

    @include break(medium) {
      text-align: center;
      margin-bottom: 30px;

      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
    }

    @include break(large) {
      flex: 1;
      margin-right: 20px;
    }
  }


  .detail-title {
    font-size: $font-subheading-size;
  }


  .image-wrapper {
    @include break(small) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include break(medium) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include break(large) {
      flex: 1;
    }
  }


  .image {
    display: block;
    width: 100%;
    max-width: 483px;
  }


  .shape-1 {
    position: absolute;
    z-index: -1;

    @include break(small-and-medium) {
      background-image: url("../../assets/home-sections/home-hero-section/shape-1-small.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;

      width: 61px;
      height: 200px;

      top: -186px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    @include break(large) {
      background-image: url("../../assets/home-sections/home-hero-section/shape-1-large.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;

      width: 234px;
      height: 200px;

      top: -186px;
      left: -220px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

  }

}

</style>
