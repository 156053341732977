<template>
  <section-container class='home-activities-section'>

    <heading id='events' class='heading' variant='b'>{{ title }}</heading>

    <div class='activities'>
      <div v-for='activity in activityList'
           :key='activity.title'
           class='activity'>
        <img class='icon' :src='activity.icon'/>
        <div class='title'>{{ activity.title }}</div>
        <div class='badge' :class='badgeClass(activity.badge)'>{{ activity.badge }}</div>
        <div class='copy' v-html='activity.copy'></div>
      </div>
    </div>

    <cta-button class='cta' href='/schedule'>View Schedule</cta-button>

  </section-container>
</template>


<script>
import SectionContainer from '@/components/SectionContainer.vue'
import Heading from '@/components/Heading.vue'
import CTAButton from '@/components/CTAButton.vue'

export default {
  name: 'HomeActivitiesSection',
  props: [
    'title',
    'activityList',
  ],
  components: {
    Heading,
    SectionContainer,
    'cta-button': CTAButton,
  },
  methods: {
    badgeClass: function(badge) {
      if (badge === "Included") {
        return 'included';
      } else {
        return 'add-on';
      }
    }
  }
}
</script>


<style scoped lang='scss'>
@import "../../css/theme";

.home-activities-section {

  .heading {
    margin-bottom: 50px;
  }


  .activities {
    @include break(large) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      margin-left: auto;
      margin-right: auto;
      max-width: 920px;
    }
  }


  .activity {
    @include break(small-and-medium) {
      text-align: center;

      margin-left: auto;
      margin-right: auto;
      max-width: 360px;

      padding-bottom: 60px;
    }


    @include break(large) {
      width: 50%;
      padding-bottom: 60px;

      &:nth-of-type(2n+1) {
        padding-right: 70px;
      }

      &:nth-of-type(2n) {
        padding-left: 70px;
      }
    }
  }


  .title {
    font-size: $font-subheading-size;
    font-weight: $font-bold-weight;
  }

  
  .badge {
    font-size: $font-small-size;
    font-style: italic;

    &.included {
      color: $royal-blue-color;
    }

    &.add-on {
      color: $lavender-color;
    }
  }


  .copy {
    margin-top: -6px;
  }


  .cta {
    margin-left: auto;
    margin-right: auto;
    max-width: 270px;
  }



}

</style>
