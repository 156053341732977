<template>
  <div class='section-container'>
    <div class='wrapper'>
      <slot></slot>
    </div>
    <div class='separator'></div>
  </div>
</template>


<script>

export default {
  name: 'SectionContainer',
}
</script>


<style scoped lang='scss'>
@import "../css/theme";

.section-container {
  position: relative;

  @include break(small) {
    padding-left: 20px;
    padding-right: 20px;

    padding-top: 80px;
    padding-bottom: 100px;
  }

  @include break(medium) {
    padding-left: 30px;
    padding-right: 30px;

    padding-top: 80px;
    padding-bottom: 100px;
  }

  @include break(large) {
    padding-left: 40px;
    padding-right: 40px;

    padding-top: 100px;
    padding-bottom: 120px;
  }


  &:first-of-type {
    @include break(small) {
      padding-top: 0;
    }
    @include break(medium) {
      padding-top: 0;
    }
    @include break(large) {
      padding-top: 0;
    }
  }


  .wrapper {
    @include break(large) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
    }

  }


  .separator {
    max-width: 594px;
    width: 100%;
    height: 18px;
    display: block;

    position: absolute;
  }

  &:nth-of-type(2n+1) .separator {
    right: 0;
    bottom: 0;
  }

  &:nth-of-type(2n) .separator {
    left: 0;
    bottom: 0;
  }

  &:nth-of-type(4n+1) .separator {
    background-image: url("../assets/section-container/shape-a.svg");
  }

  &:nth-of-type(4n+2) .separator {
    background-image: url("../assets/section-container/shape-b.svg");
  }

  &:nth-of-type(4n+3) .separator {
    background-image: url("../assets/section-container/shape-c.svg");
  }

  &:nth-of-type(4n) .separator {
    background-image: url("../assets/section-container/shape-d.svg");
  }

  &:last-of-type .separator {
    background-image: none;
  }

}

</style>
