<template>
  <footer class='footer-section'>
    <div class='wrapper'>

      <div class='content'>
        <img class='logo' src='@/assets/jamstack-conf-logo.svg'/>

        <div class='description' v-html='content.copy'></div>

        <div class='info'>
          <div class='date'>{{ content.conferenceDate }}</div>
          <div class='location'>{{ content.conferenceLocation }}</div>
        </div>


        <transition name='fade' mode='out-in'>
          <div class='cta-box' v-if='formState === "form"' key='1'>

            <form class='cta-form'
                  name='mailing-list-footer'
                  method='post'
                  action='/'
                  @submit.prevent='handleSubmit'
                  netlify>
              <input class='email-input'
                     type='email'
                     name='email'
                     v-model='email'
                     placeholder='you@example.com'/>
              <input type='hidden' name='form-name' value='mailing-list-footer'/>
              <button class='submit-button' type='submit'>{{ content.cta }}</button>

            </form>
          </div>


          <div class='cta-box' v-if='formState === "success"'>
            <div class='form-response'>
              <img class='form-response__img' src='@/assets/footer-section/form-success.svg'/>
              <div class='form-response__message'>
                Success! JAMstack_conf news will be delivered directly to your
                inbox.
              </div>
            </div>
          </div>

          <div class='cta-box' v-if='formState === "error"' key='2'>

            <div class='form-response'>
              <img class='form-response__img' src='@/assets/footer-section/form-error.svg'/>
              <div class='form-response__message'>
                Error! We were unable to add you to the JAMstack_conf
                newsletter.
              </div>
            </div>

          </div>
        </transition>


        <div class='links'>
          <ul>
            <li class='link-item'
                v-for='link in content.linksCol1'
                :key='link.url'>

              <a v-if='isExternalLink(link.url)'
                 :href='link.url'
                 target='_blank'>{{ link.label }}</a>
              <router-link v-else
                           :to='link.url'>{{ link.label }}</router-link>
            </li>
          </ul>

          <ul>
            <li class='link-item'
                v-for='link in content.linksCol2'
                :key='link.url'>

              <a v-if='isExternalLink(link.url)'
                 :href='link.url'
                 target='_blank'>{{ link.label }}</a>
              <router-link v-else
                           :to='link.url'>{{ link.label }}</router-link>
            </li>
          </ul>
        </div>
      </div>



      <div class='shape-14'></div>

    </div>
  </footer>
</template>


<script>

import content from '@/content/footer.json'

export default {
  name: 'FooterSection',
  data: function() {
    return {
      'formState': 'form',  // 'form', 'success', or 'error'
      'content': content,
      'email': ''
    }
  },

  methods: {
    handleSubmit() {
      if (!this.email) {
        // Don't try to submit an empty email
        return;
      }
      // Send the form to `/` for Netlify to process
      let url = '/';
      let data = {
        'form-name': 'mailing-list-footer',
        'email': this.email
      }
      this.$http.post(url, data).then(() => {
        this.formState = 'success';
      }, () => {
        this.formState = 'error';
      });
    },

    isExternalLink: function(url) {
      if (url.startsWith('/')) {
        return false;
      }
      return true;
    }
  }

}
</script>



<style scoped lang='scss'>
@import "../css/theme";

.footer-section {
  background-color: $light-gray-bg-color;

  padding-left: 20px;
  padding-right: 20px;

  position: relative;
  z-index: 100;

  @include break(small-and-medium) {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  @include break(large) {
    padding-top: 80px;
  }


  .wrapper {
  @include break(large) {
      max-width: 780px;
      margin-left: auto;
      margin-right: auto;

      overflow: hidden;  /* .content float clear */
    }
  }


  .intro {
    text-align: center;

    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

    max-width: 500px;
  }


  .content {
    padding-left: 20px;
    padding-right: 20px;

    margin-left: auto;
    margin-right: auto;
    max-width: 420px;

    @media (max-width: 450px) {
      padding-left: 0;
      padding-right: 0;
    }


    @include break(large) {
      float: right;
      padding-bottom: 40px;
    }

  }



  .logo {
    width: 159px;
    height: 57px;

    display: block;
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 10px;

    @include break(large) {
      margin-left: 0;
    }
  }


  .description {
    font-size: 15px;
    line-height: 1.6;

    text-align: center;

    margin-bottom: 40px;


    @include break(large) {
      text-align: left;

      margin-bottom: 26px;
    }
  }


  .info {
    font-size: $font-small-size;

    display: flex;
    justify-content: space-around;
    align-items: center;

    margin-bottom: 50px;

    @media (max-width: 450px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      text-align: center;
    }

    @include break(large) {
      justify-content: flex-start;
      margin-bottom: 36px;
    }
  }


  .date {
    color: #3F7AF1;

    @media (max-width: 450px) {
      margin-bottom: 20px;
    }

    @include break(large) {
      margin-right: 20px;
    }
  }


  .location {
    color: #CD63FF;
  }


  .cta-box {
    background-color: #FFF;
    box-shadow: $small-box-shadow;

    @include break(small-and-medium) {
      height: 200px;
      display: flex;
      align-items: center;

      padding-left: 20px;
      padding-right: 20px;

      margin-left: 20px;
      margin-right: 20px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include break(large) {
      height: 90px;
      display: flex;
      align-items: center;

      padding-left: 25px;
      padding-right: 25px;
    }
  }


  .cta-form {
    @include break(large) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }


  .email-input {
    border: none;
    border-bottom: 2px solid $pink-color;
    background-color: #F8F8F8;
    border-radius: none;

    font-size: 14px;

    padding-top: 12px;
    padding-bottom: 12px;


    &:hover {
      color: #000;
      background-color: #FFF;
      border-bottom: 2px solid #000;
      outline: none;
    }

    &:focus {
      color: #000;
      background-color: #FFF;
      border-bottom: 2px solid #000;
      outline: none;
    }


    @include break(small-and-medium) {
      display: block;

      text-align: center;

      padding-left: 30px;
      padding-right: 30px;

      margin-bottom: 30px;
    }


    @media (max-width: 450px) {
      font-size: 13px;

      padding-left: 10px;
      padding-right: 10px;
    }


    @include break(large) {
      margin-right: 30px;

      padding-left: 20px;
      padding-right: 20px;

      flex: 2;
      width: 100%;
    }


    &::placeholder {
      color: #CECECE;
    }
  }


  .submit-button {
    background-color: #FFF;
    border: 4px solid blue;
    border-image: $blue-green-gradient;
    border-image-slice: 4;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

    white-space: nowrap;

    font-size: $font-small-size;
    font-weight: $font-bold-weight;

    cursor: pointer;

    flex: 1;
    width: 100%;

    &:hover {
      border: 4px solid #000;
      border-image: none;
      outline: none;
    }

    &:hover {
      color: $lavender-color;
      border-image: $pink-purple-gradient;
      border-image-slice: 4;
      outline: none;
    }

    @media (max-width: 450px) {
      font-size: 13px;
    }
  }



  .links {
    box-sizing: border-box;
    background-color: #F9F9F9;

    margin-top: 40px;

    @media (max-width: 380px) {
      margin-left: 40px;
      margin-right: 40px;
    }


    @media (min-width: 381px) {
      margin-left: 20px;
      margin-right: 20px;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }


    @include break(small-and-medium) {
      justify-content: space-around;
    }


    ul {
      list-style-type: none;

      margin: 0;
      padding: 0;

      @include break(large) {
        margin-right: 30px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }


    li {
      margin-bottom: 15px;
    }


    a {
      color: #000;
      font-weight: 400;
      font-size: 12px;
      text-decoration: none;

      margin-right: 20px;
    }


    a:hover {
      border-bottom: 1px solid #000;
    }
  }


  .form-response {

    @include break(small-and-medium) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include break(large) {
      display: flex;
      align-items: center;
    }


    &__img {
      display: block;

    @include break(small-and-medium) {
        margin-bottom: 20px;
      }

      @include break(large) {
        margin-right: 20px;
      }
    }

    &__message {
      font-size: 13px;
      line-height: 1.5;

      @include break(small-and-medium) {
        text-align: center;
      }

    }

  }



  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .3s ease-out;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-to {
    opacity: 1;
  }

  .fade-leave {
    opacity: 1;
  }

  .fade-leave-to {
    opacity: 0;
  }




  .shape-14 {
    @media (min-width: 851px) {
      background-image: url("../assets/footer-section/shape-large.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 360px;
      height: 292px;

      position: absolute;
      bottom: 0;
      left: 0;

      z-index: -2;
    }


    @media (min-width: 1000px) {
      width: 450px;
      height: 365px;
    }
  }

}
</style>
